interface TranslationList {
  [key: string]: string;
}

export const localizationRussian: TranslationList = {
  home: "Главная",
  merchants: "Торговцы ",
  faq: "Часто задаваемые вопросы",
  login: "Вход",
  sign_up: "Регистрация",
  support: "Поддержка",
  usa: "Eng",
  rus: "Рус",
  arm: "Հայ",
  cn: "中文",
  copyright: "Авторские права ©",
  copyright_crypto: "Коббекс",
  about: "О Нас",
  about_us: "О Компании",
  ongoing_trades: "Текущие сделки",
  legal: "Юридическая информация",
  terms_of_use: "Условия использования",
  privacy_policy: "Политика конфиденциальности",
  contact_us: "Связь с нами",
  social_media: "Социальные сети",
  name_input: "Имя/Фамилия*",
  email: "Электронная почта*",
  message: "Сообщение*",
  all: "Все",
  buy: "Купить",
  sell: "Продать",
  search: "Поиск",
  filter: "Фильтр",
  merchant: "Торговец",
  currency: "Валюта",
  amount: "Сумма",
  price_rate: "Курс цены (доллар США)",
  banks: "Банки",
  trade: "Торговля",
  date_of_birth: "Дата рождения",
  phone_number: "Номер телефона*",
  passport_number: "Номер паспорта",
  passport_series: "Серия паспорта",
  passport_image: "Изображение паспорта/удостоверения личности*",
  passport_image_without_mandatory:
    "Изображение паспорта/удостоверения личности",
  password: "Пароль*",
  confirm_password: "Подтвердите пароль*",
  info_title:
    "Пожалуйста, загрузите фото, где вы держите свой паспорт. Убедитесь, что:",
  done: "Готово",
  already_have_account: "Уже есть аккаунт?",
  telegram_name: "Имя в Telegram",
  nickname: "Имя пользователя*",
  first_name: "Имя*",
  last_name: "Фамилия*",
  dont_have_account: "Еще нет аккаунта?",
  create_trade: "Создать Сделку",
  my_wallet: "Мой Кошелек",
  forgot_password: "Забыли Пароль?",
  two_factor_auth: "Двухфакторная Аутентификация",
  two_factor_auth_desc:
    "Загрузите приложение аутентификации от Microsoft или Google на свое мобильное устройство и отсканируйте этот QR-код.",
  cant_scan_qr: "Не удается отсканировать QR-код?",
  qr_scan_desc:
    "Если вы не можете отсканировать QR-код, введите этот код в свое приложение аутентификации.",
  enter_verification_code: "Введите Код Подтверждения",
  account: "Аккаунт",
  wallet: "Кошелек",
  withdrawal: "Вывод средств",
  balance_history: "История баланса",
  transaction_history: "История транзакций",
  logout: "Выйти",
  logout_modal_text: "Вы уверены, что хотите выйти из своего аккаунта?",
  yes: "Да",
  cancel: "Отмена",
  forgot_password_title: "Забыл(а) пароль",
  forgot_pass_modal_desc:
    "Введите свой адрес электронной почты, и мы отправим вам инструкции по сбросу пароля.",
  email_address: "Адрес электронной почты*",
  send_instruction: "Отправить инструкцию",
  new_password: "Новый пароль*",
  success: "Успех",
  error: "Ошибка",
  info: "Информация",
  warning: "Предупреждение",
  change_password: "Изменить пароль",
  reset_password: "Сбросить пароль",
  set_password: "Установить пароль",
  password_info:
    "Пароль должен содержать от 6 до 20 символов, включая хотя бы одну заглавную букву, одну строчную букву и одну цифру.",
  language: "Язык",
  required: "Обязательно",
  pattern_error:
    "Пароль должен содержать от 6 до 20 символов, включая хотя бы одну заглавную букву, одну строчную букву и одну цифру.",
  okay: "Ок",
  thank_you_for_register: "Спасибо за регистрацию",
  congrats_for_register: "Поздравляем с регистрацией",
  email_check_message:
    "Пожалуйста, проверьте свою электронную почту, чтобы подтвердить регистрацию.",
  password_matches: "Пароли не совпадают",
  email_pattern_error: "Формат электронной почты недействителен",
  example: "Пример",
  upload_image: "Загрузить изображение или перетащить",
  become_merchant: "Стать торговцем ",
  submit: "Отправить",
  balance: "Баланс",
  save_changes: "Сохранить изменения",
  inactive: "Неактивный",
  activated: "Активирован",
  activate: "Активировать",
  inactivate: "Деактивировать",
  old_password: "Старый пароль*",
  current_password: "Текущий пароль*",
  im_agree: "Я согласен с",
  and: "и",
  load_more: "Загрузить еще",
  price_rate_without: "Курс цены",
  create: "Создать",
  home_table_title: "Продавать и покупать криптовалюту легко",
  rows_per_page: "Строк на странице:",
  request_trade: "Запрос сделки",
  request_trade_text:
    "Введите сумму. Ваш запрос будет отправлен продавцу. Если он будет одобрен в течение 30 минут, откроется чат. В противном случае запрос автоматически аннулируется.",
  offline_request_trade_text:
    "Введите сумму. Ваш запрос будет отправлен продавцу. Если он будет одобрен, откроется чат.",
  request_trade_info:
    "Сумма не может превышать количество, предлагаемое продавцом.",
  active: "Активный",
  trade_id: "ID сделки",
  activate_trade:
    "Вы уверены, что хотите активировать сделку? Активация сделки отобразит ее в таблице сделок.",
  inactivate_trade:
    "Вы уверены, что хотите деактивировать сделку? Деактивация сделки удалит ее из таблицы сделок.",
  close_trade: "Закрыть сделку",
  close_trade_popup_text:
    "Вы уверены, что хотите закрыть эту сделку? Закрытие сделки завершит чат, и сделка будет перемещена в вашу историю транзакций.",
  close_trade_text:
    "Вы уверены, что хотите закрыть сделку? Закрытие сделки удалит ее из таблицы сделок, и это действие нельзя будет отменить.",
  id: "Идентификатор (ID)",
  requests: "Запросы",
  select_trade: "Выбрать сделку",
  select_trade_text:
    "Выберите сделку из списка, чтобы просмотреть все связанные запросы.",
  select_request: "Выбрать запрос",
  select_request_text:
    "Выберите запрос из списка, чтобы просмотреть все детали.",
  pending: "В ожидании",
  requested_amount: "Запрашиваемая cумма:",
  user_equest_time: "Время запроса пользователя:",
  accept: "Принять",
  accept_request: "Принять запрос",
  accept_request_text:
    "Вы уверены, что хотите принять запрос? Принятие запроса откроет чат с пользователем.",
  cancel_request: "Отменить запрос",
  cancel_request_text:
    "Вы уверены, что хотите отменить этот запрос? Это действие нельзя будет отменить.",
  in_progress: "В процессе",
  request_id: "ID Запроса",
  go_to_chat: "Перейти в чат",
  min_amount: "Минимальная сумма",
  max_amount: "Максимальная сумма",
  arbitrage: "Арбитраж",
  confirm: "Подтвердить",
  merchant_cancel_deal: " отменил(а) сделку",
  merchant_reject_deal: " отклонил(а) сделку",
  canceled_deal_message:
    "Этот чат будет закрыт, а сделка будет перенесена в историю транзакций.",
  user_conform_deal: " подтвердил(а) сделку",
  sender_conform_deal: "Вы подтвердили сделку",
  sender_cancel_deal: "Вы отменили сделку",
  sender_reject_deal: "Вы отклонили сделку",
  merchant_conform_deal: " подтвердил(а) сделку",
  confirm_deal_message:
    "Если вы также подтвердите, чат будет закрыт, и сделка будет завершена.",
  confirm_deal_message_sender_first: "Если ",
  confirm_deal_message_sender_second:
    " также подтвердит, чат закроется, и сделка будет завершена.",
  cancel_trade: "Отменить сделку",
  cancel_trade_message:
    "Вы уверены, что хотите отменить сделку? Отмена сделки закроет чат, и сделка будет перенесена в историю транзакций.",
  initiate_arbitrage: "Инициировать арбитраж",
  initiate_arbitrage_message:
    "Хотите начать процесс арбитража? Если вы выберете «Да», администратор присоединится к вашему чату, чтобы помочь вам на протяжении всего процесса.",
  currencies: "Валюта",
  confirm_trade: "Подтвердить",
  confirm_trade_text: "Вы уверены, что хотите подтвердить сделку?",
  closed: "Закрыто",
  trade_time: "Время сделки:",
  deal_id: "ID сделки",
  rate: "Курс",
  status: "Статус",
  date: "Дата",
  action: "Действие",
  id_nickname: "ID/Имя пользователя",
  view_details: "Просмотреть детали",
  canceled: "Отменено",
  completed: "Завершено",
  source: "Источник",
  type: "Тип",
  outcome: "Исходящие средства",
  income: "Входящие средства",
  type_message: "Введите сообщение...",
  currency_mandatory: "Валюта*",
  amount_mandatory: "Сумма*",
  price_rate_mandatory: "Курс цены*",
  banks_mandatory: "Банки*",
  quantity: "Количество",
  please_scan_qr:
    "Пожалуйста, отсканируйте QR-код или скопируйте сгенерированный адрес для депозита ваших криптоактивов.",
  wallet_address: "Адрес кошелька",
  withdraw: "Вывести",
  step: "Шаг",
  step_one_desc:
    "Легко переводите средства с вашего кошелька на желаемый счет с помощью нашего упрощенного процесса вывода.",
  step_two_desc:
    "Для начала процесса вывода требуется верификация аккаунта. Пожалуйста, завершите процесс верификации, чтобы продолжить.",
  withdrawal_amount_mandatory: "Сумма вывода*",
  destination_address_mandatory: "Адрес назначения*",
  next: "Далее",
  proceed_withdrawal: "Продолжить вывод",
  accepted: "В процессе",
  rejected: "Отменено",
  trade_details: "Детали сделки",
  no_requests: "Нет запросов",
  no_available_requests: "Нет доступных запросов для этой сделки.",
  value_must_be_greater: "Вы должны указать сумму больше 0",
  value_must_be_greater_than_currency: "Вы должны указать сумму больше ",
  dont_have_enough_funds: "У вас недостаточно средств",
  confirm_pass: "Подтвердите пароль",
  ongoing_requests: "Текущие запросы",
  request: "Запрос",
  for_withdrawals_please: "Для вывода средств, пожалуйста",
  two_factor_authentication: "двухфакторная аутентификация (2FA)",
  two_fa_error_message: "Требуется двухфакторная аутентификация (2FA)",
  empty_message_active: "Активные сделки не найдены",
  empty_message_inactive: "Неактивные сделки не найдены",
  empty_message_accepted: "Запросы в процессе не найдены",
  empty_message_pending: "Ожидающие запросы не найдены",
  empty_message_trade: "Доступные сделки отсутствуют",
  empty_message_request: "Доступные запросы отсутствуют",
  sender_arbitraged: "Вы сделали арбитраж",
  user_arbitraged: " сделал(а) арбитраж",
  arbitrage_message: "Администратор приглашен в чат.",
  trade_completed: "Сделка Завершена",
  trade_rate_modal_text:
    "Сделка успешно завершена. Спасибо за сотрудничество. Пожалуйста, уделите минуту, чтобы оценить друг друга.",
  value_must_be_greater_than: "Значение должно быть больше или равно 0.000001",
  two_factor_disable_message:
    "Для продолжения введите код, предоставленный приложением Google Authenticator",
  address_pattern_error:
    "Адрес должен содержать только английские буквы и цифры",
  no_results_match: "Результатов не найдено.",
  no_item_matched: "Элемент не найден.",
  please_try_with_other_options: "Пожалуйста, попробуйте другие параметры",
  no_trades: "Сделок нет",
  no_available_trades: "Доступных сделок нет",
  no_requests_available: "Доступных запросов нет",
  verified: "Подтвержено",
  usd_mandatory: "Доллар США*",
  crypto_coin: "Криптовалюта",
  crypto_coin_mandatory: "Криптовалюта*",
  usd_price_rate: "Курс доллара США/1$ ~ армянский драм*",
  verify_checkbox_text:
    "Запросы могут отправлять только верифицированные пользователи",
  verify_your_account: "Верификация аккаунта",
  verify_account_modal_message:
    "Чтобы отправить запрос на эту сделку, вы должны быть верифицированным пользователем (вы не добавили изображение своего паспорта при создании учетной записи). Пожалуйста, перейдите в свою учетную запись и загрузите изображение паспорта, чтобы завершить процесс верификации.",
  verify_account_pending_message:
    "Ваш запрос на верификацию аккаунта в настоящее время обрабатывается. Чтобы отправить запрос на эту сделку, вы должны быть подтвержденным пользователем. Пожалуйста, дайте нашей команде некоторое время для проверки вашего запроса. Если вы уже загрузили изображение вашего паспорта и ждете долгое время или возникают какие-либо проблемы, пожалуйста, свяжитесь с нашей службой поддержки.",
  go_to_profile: "Перейти в профиль",
  one_usd_equivalent: "Один доллар США эквивалентен",
  amd: "Армянский драм",
  usd: "Доллар США",
  usd_to_amd: "Доллар США/Драм",
  upload_image_profile: "Загрузить изображение Паспорта",
  satisfied_customers: "Довольные клиенты",
  platform_uptime: "Время работы платформы",
  armenian_market: "Армянский рынок",
  no_transactions_found: "Транзакции не найдены",
  no_data: "Данные отсутствуют",
  no_balance_history: "История баланса не найдена",
  my_cards: "Мои карты",
  no_cards: "Нет карт",
  no_cards_desc:
    "Карты еще не добавлены. Нажмите на кнопку, чтобы добавить новую карту.",
  add_card: "Добавить карту",
  card_number: "Номер карты*",
  card_name: "Имя карты*",
  card_name_text: "Введите имя, как указано на карте.",
  add: "Добавить",
  name_surname: "ИМЯ ФАМИЛИЯ",
  delete_card: "Удалить карту",
  delete_card_text:
    "Это действие не может быть отменено. Если вы продолжите, карта будет невозвратно удалена с вашего профиля.",
  delete: "Удалить",
  mark_offline_trade: "Совершить оффлайн сделку",
  location_mandatory: "Локация*",
  location: "Локация",
  my_cards_mandatory: "Мои карты*",
  change_card: "Сменить карту",
  no_card_added: "Нет добавленных карт.",
  add_card_plus: "+Добавить карту",
  save: "Сохранить ",
  enter_valid_card: "Ввести действительную карту",
  online: "Онлайн",
  offline: "Оффлайн",
  close: "Закрыть",
  min_max_character_count: "Имя карты должно быть длиннее 2 и короче 50",
  location_pattern_error:
    "От 2 до 50 символов (должны содержать только буквы, цифры и #, -, /, ., ', &) .",
  request_is_arbitraged: "Запрос находится в арбитраже",
  passport_image_info: "Ваше лицо и данные на паспорте четко видны.",
  passport_image_info_step_one:
    "Изображение в фокусе, хорошо освещено и без бликов.",
  passport_image_info_step_two: "Все четыре угла паспорта видны.",
  max_size: "Максимальный размер:",
  five_megabit: "10MB",
  format: "Формат:",
  format_types: "(JPG, JPEG, PNG)",
  fee_modal_text:
    "Пожалуйста, внимательно проверьте детали транзакции. Подтверждаете ли вы свое согласие на продолжение?",
  fee_calculation: "Расчет платы",
  transfer: "Transfer",
  deposit: "Deposit",
  actual_amount: "Фактическая сумма:",
  fee: "Комиссия:",
  fee_text:
    "(Комиссия не взимается за транзакции в рамках нашего сервиса; для внешних транзакций может применяться комиссия.)",
  account_restricted: "Аккаунт Заблокирован",
  account_restricted_text:
    "Ваш аккаунт частично заблокирована. Ваш аккаунт частично ограничен. Действия, такие как вывод средств, создание сделок или запрос сделок, временно недоступны. Для дополнительной поддержки, пожалуйста, свяжитесь с нашей службой поддержки.",
  network_connection_error:
    "Похоже, ваше интернет-соединение потеряно. Пожалуйста, проверьте соединение и попробуйте снова",
  big_image_error_msg:
    "Один или несколько файлов превышают лимит размера в 10 МБ.",
  price_rate_error_online:
    "Сумма сделки должна быть в пределах от $20 до $10,000. Обратите внимание, что из-за колебаний валюты пределы могут изменяться до 2%.",
  price_rate_error_offline:
    "Сумма сделки должна быть в пределах от $5000 до $200,000. Обратите внимание, что из-за колебаний валюты пределы могут изменяться до $10.",
  price_rate_request_error_online:
    "Сумма должна быть в пределах от $20 до $10,000. Обратите внимание, что из-за колебаний валюты пределы могут изменяться до 2%.",
  price_rate_request_error_offline:
    "Сумма должна быть в пределах от $5000 до $200,000. Обратите внимание, что из-за колебаний валюты пределы могут изменяться до $10.",
};
