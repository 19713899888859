import { FC } from "react";

import { HOME_PAGE_TABS, HOME_PAGE_TABS_BASIC } from "constants/table";
import { MainTab } from "components";
import { TableActions } from "./TableActions";
import { ITableHeader } from "types";
import { GLOBAL } from "constants/global";

export const TableHeader: FC<ITableHeader> = ({
  openFilters,
  setOpenFilters,
  refresh,
  setRefresh,
  activeTab,
  setActiveTab,
  isMerchant,
}) => {
  return (
    <div className="home_table_header">
      <MainTab
        data={isMerchant ? HOME_PAGE_TABS : HOME_PAGE_TABS_BASIC}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        isQuery={true}
        queryKey={GLOBAL.tab}
      />
      <TableActions
        openFilters={openFilters}
        setOpenFilters={setOpenFilters}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    </div>
  );
};
