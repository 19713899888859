import { FC, useEffect, useState } from "react";

import { useAppSelector } from "reduxState/store";
import { ChatMessages } from "./ChatMessages";
import { IChatMessages, IUserRequest } from "types";

interface IChatSection {
  requestData: IUserRequest;
}

export const ChatSection: FC<IChatSection> = ({ requestData }) => {
  const messagesList = useAppSelector((state) => state.trades.messages);

  const [messages, setMessages] = useState<IChatMessages[]>(messagesList);

  useEffect(() => {
    setMessages(messagesList);
  }, [messagesList]);

  return (
    <div className="chat_section">
      <ChatMessages messages={messages} requestData={requestData} />
    </div>
  );
};
