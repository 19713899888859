import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";

import { useTranslation } from "localization";
import { ModalContainer } from "../ModalAuth";
import { MainButton } from "components/buttons";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { SelectBox, TextInput } from "components/inputs";
import { setOpenToast } from "reduxState/features/toast";

import closeIcon from "assets/images/close.svg";
import requestTradeIcon from "assets/images/requestTrade.svg";
import { requestTrade } from "reduxState";
import useOutsideClick from "hooks/useOutsideClick";
import { InfoSection } from "./InfoSection";
import { COIN_USD_NAMES, PRICE_MIN_MAX } from "constants/global";
import { ICard, ITradeModalData } from "types";
import { socket } from "socket";
import { getNumericNumbers, isTradeResponse } from "helpers";
import { TRADES_TABS, TRADE_FORMAT_TYPES } from "constants/trade";
import { TradeCreateCards } from "components/cards";

import dangerIcon from "assets/images/danger.svg";

interface ITradesModal {
  tradeId: string;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  tradeData: ITradeModalData;
}

export const TradeRequestModal: FC<ITradesModal> = ({
  tradeId,
  setShowModal,
  tradeData,
}) => {
  const [priceRate, setPriceRate] = useState<string>("");
  const [selectedCardId, setSelectedCardId] = useState<number | null>(null);
  const [defaultCard, setDefaultCard] = useState<undefined | ICard>(undefined);
  const [isPriceRateError, setIsPriceRateError] = useState<boolean>(false);
  const [priceErrorMessage, setPriceErrorMessage] = useState<string>("");

  const loading = useAppSelector((state) => state.trades.requestTradeLoading);
  const banks = useAppSelector((state) => state.banksCurrencies.banks);
  const currenciesRate = useAppSelector(
    (state) => state.banksCurrencies.currenciesRate
  );
  const cards = useAppSelector((state) => state.cardsData.cards);

  const existingBanks = banks.filter((elem) =>
    tradeData.banks?.includes(`${elem.id}`)
  );

  const translation = useTranslation();
  const dispatch = useAppDispatch();

  const ref = useRef<HTMLDivElement>(null);

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    getValues,
    formState: { errors, isSubmitted },
  } = useForm();

  const handleClose = () => {
    setShowModal(false);
  };

  const handleConfirm = () => {
    setShowModal(false);
  };

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    const newData = {
      ...data,
      banks: !!data?.banks?.length
        ? data.banks
        : existingBanks.map((elem) => elem.id),
    };

    if (tradeData.format === TRADE_FORMAT_TYPES.OFFLINE) {
      delete newData.banks;
    }

    if (
      tradeData.format === TRADE_FORMAT_TYPES.OFFLINE &&
      (PRICE_MIN_MAX.min_offline_price > Number(priceRate) ||
        PRICE_MIN_MAX.max_offline_price < Number(priceRate))
    ) {
      setIsPriceRateError(true);
      setPriceErrorMessage("price_rate_request_error_offline");
      return;
    } else if (
      tradeData.format === TRADE_FORMAT_TYPES.ONLINE &&
      (PRICE_MIN_MAX.min_online_price > Number(priceRate) ||
        PRICE_MIN_MAX.max_online_price < Number(priceRate))
    ) {
      setIsPriceRateError(true);
      setPriceErrorMessage("price_rate_request_error_online");
      return;
    }

    dispatch(requestTrade({ data: newData, navigate: handleConfirm }))
      .finally(() => {
        setTimeout(() => {
          dispatch(setOpenToast(false));
        }, 3000);
      })
      .then((res: unknown) => {
        if (isTradeResponse(res)) {
          const tradeRequestId = res.payload.data.data.data.id;
          socket.emit("newTradeRequest", {
            tradeId,
            tradeRequestId,
          });
        }
      });
  };

  const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = getNumericNumbers(e.target.value);

    setIsPriceRateError(false);
    setPriceErrorMessage("");
    setPriceRate(value);
    if (value === "") {
      setValue(COIN_USD_NAMES.amount, "");
    } else {
      const currencyRate = currenciesRate[tradeData.currencyName]?.price;
      const formatAmount = (Number(value) / Number(currencyRate)).toFixed(4);

      setValue(COIN_USD_NAMES.amount, `${formatAmount}`);
      clearErrors(COIN_USD_NAMES.amount);
    }
  };

  const handleAmountFieldChange = (value: string) => {
    if (value === "") {
      setPriceRate("");
    } else {
      const currencyRate = currenciesRate[tradeData.currencyName]?.price;
      const formatPrice = (Number(value) * Number(currencyRate)).toFixed(2);

      setPriceRate(`${formatPrice}`);
    }
    setIsPriceRateError(false);
    setPriceErrorMessage("");
  };

  useOutsideClick({ ref, handler: handleClose });

  useEffect(() => {
    if (!!tradeId) setValue("trade_id", tradeId);
  }, [tradeId]);

  useEffect(() => {
    if (defaultCard) {
      setValue("cardId", defaultCard.id);
      setSelectedCardId(defaultCard.id);
    }
  }, [defaultCard]);

  useEffect(() => {
    if (cards.length) {
      const selectedCard = selectedCardId
        ? cards.find((elem) => elem.id === selectedCardId)
        : cards?.find((elem) => elem.isDefault);
      setDefaultCard(selectedCard);
    }
  }, [selectedCardId, cards]);

  return (
    <ModalContainer>
      <div className="modal" ref={ref}>
        <div className="logout_header">
          <div className="close_modal">
            <img src={closeIcon} alt="Close" onClick={handleClose} />
          </div>
          <div className="logout_header_element">
            <img src={requestTradeIcon} alt="Logout" className="logout_image" />
            <p className="logout_title">{translation.request_trade}</p>
          </div>
        </div>
        <div className="logout_body">
          <p className="logout_description">
            {tradeData.format === TRADE_FORMAT_TYPES.ONLINE
              ? translation.request_trade_text
              : translation.offline_request_trade_text}
          </p>
        </div>
        <form className="modal_form" onSubmit={handleSubmit(onSubmit)}>
          <div className="input_with_info">
            <div className="request_trade_field">
              <div className={`text_input_container`}>
                <label className="input_label">{translation.usd}</label>
                <div className="input_field">
                  <div className="input_filed_content">
                    <input
                      name={COIN_USD_NAMES.usd}
                      value={priceRate}
                      onChange={(e) => handlePriceChange(e)}
                      id={COIN_USD_NAMES.usd}
                      className="text_input disabled_text_input"
                      autoComplete="new-password"
                    />
                  </div>
                </div>
              </div>
              <span className="equal_field">=</span>
              <TextInput
                name="amount"
                label={translation.crypto_coin_mandatory}
                register={register}
                errors={errors}
                setValue={setValue}
                isNumber
                required={true}
                setFieldValue={handleAmountFieldChange}
              />
            </div>
            {!errors.amount && !isPriceRateError && (
              <InfoSection priceRate={tradeData.priceRate} />
            )}
            {isPriceRateError && (
              <div className="error_field">
                <img src={dangerIcon} alt="Danger" className="danger_icon" />
                <p className="error_message">
                  {translation[priceErrorMessage]}
                </p>
              </div>
            )}
          </div>
          {tradeData.format === TRADE_FORMAT_TYPES.ONLINE && (
            <SelectBox
              name="banks"
              label={translation.banks_mandatory}
              register={register}
              errors={errors}
              setValue={setValue}
              isMultiple={true}
              getValues={getValues}
              clearErrors={clearErrors}
              setError={setError}
              isSubmitted={isSubmitted}
              existingBanks={existingBanks}
            />
          )}
          {tradeData.type === TRADES_TABS[0].value &&
            tradeData.format === TRADE_FORMAT_TYPES.ONLINE && (
              <TradeCreateCards
                isError={!!errors?.cardId}
                selectedCardId={selectedCardId}
                setSelectedCardId={setSelectedCardId}
                tradeRegister={register}
                name="cardId"
                setTradeCardValue={setValue}
                clearFormErrors={clearErrors}
                defaultCard={defaultCard}
              />
            )}
          <div className="logout_actions">
            <MainButton
              name={translation.request_trade}
              customClass="custom_button width_100"
              type="submit"
              loading={loading}
              disabled={loading}
            />
          </div>
        </form>
      </div>
    </ModalContainer>
  );
};
