import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import {
  HomePage,
  SignUp,
  SignIn,
  ResetPassword,
  SetPassword,
  MerchantSignUp,
  ForMerchants,
  ProfileAccount,
  TermsOfUse,
  PrivacyPolicy,
  FaqPage,
  OngoingTrades,
  Transactions,
  BalanceHistory,
  Wallets,
  Withdraw,
  ForgotPassword,
  ProfileCards,
} from "pages";
import { Layout } from "layout";
import { useAppDispatch, useAppSelector } from "./reduxState/store";
import { socket } from "./socket";
import {
  popLastTrade,
  setTradeList,
  setTradesFromSocket,
} from "reduxState/features/trades";
import { GLOBAL } from "constants/global";
import { setCurrenciesRate } from "./reduxState/features/banksCurrencies";
import { TRADE_FORMAT_TYPES } from "./constants/trade";

export const PagesRouter = () => {
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const tradesList = useAppSelector((state) => state.trades.tradesList);

  const query = new URLSearchParams(useLocation().search);
  const perPage = query.get(GLOBAL.per_page) || GLOBAL.default_per_page;
  const format = query.get(GLOBAL.format);

  useEffect(() => {
    socket.on("connect", () => {});

    socket.on("emitTrade", (data) => {
      if (
        data.format === format ||
        (!format && data.format === TRADE_FORMAT_TYPES.ONLINE)
      ) {
        dispatch(setTradesFromSocket(data));
        dispatch(popLastTrade({ perPage: Number(perPage) }));
      }
    });

    socket.emit("getRates");

    socket.on("rates", (data) => {
      dispatch(setCurrenciesRate(data));
    });

    socket.on("updateTradeRequestCount", (id: string) => {
      if (id && tradesList?.length)
        dispatch(
          setTradeList(
            tradesList?.map((elem) =>
              elem.id === Number(id)
                ? { ...elem, requestCount: elem.requestCount + 1 }
                : elem
            )
          )
        );
    });

    socket.on("disconnect", () => {});

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("emitTrade");
      socket.off("rates");
    };
  }, [perPage, format, tradesList]);

  useEffect(() => {
    setTimeout(() => setLoading(true), 0);
  }, []);

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/for-merchants" element={<ForMerchants />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/set-password" element={<SetPassword />} />
        <Route path="/complete-registration" element={<MerchantSignUp />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/faq" element={<FaqPage />} />
        {!user ? (
          <>
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </>
        ) : (
          <>
            <Route path="/profile/account" element={<ProfileAccount />} />
            <Route path="/profile/transaction" element={<Transactions />} />
            <Route path="/profile/balance" element={<BalanceHistory />} />
            <Route path="/profile/wallet" element={<Wallets />} />
            <Route path="/profile/withdrawal" element={<Withdraw />} />
            <Route path="/profile/cards" element={<ProfileCards />} />
            <Route path="/ongoing-trades" element={<OngoingTrades />} />
          </>
        )}
        {loading && <Route path="*" element={<Navigate to="/" />} />}
      </Routes>
    </Layout>
  );
};
