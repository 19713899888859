import { useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { useTranslation } from "localization";
import { ModalContainer } from "../ModalAuth";
import { MainButton } from "components/buttons";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { setShowCloseTrade } from "reduxState/features/main";
import { closeTrade } from "reduxState";
import useOutsideClick from "hooks/useOutsideClick";
import { CLOSE_STATE } from "constants/trade";
import { setOpenToast } from "reduxState/features/toast";
import { GLOBAL } from "constants/global";

import closeIcon from "assets/images/close.svg";
import trashIcon from "assets/images/trash.svg";

export const CloseTradeModal = () => {
  const [disableConfirm, setDisableConfirm] = useState<boolean>(false);

  const tradeId = useAppSelector((state) => state.main.closeTradeId);
  const tab = useAppSelector((state) => state.main.activeTab);
  const loading = useAppSelector((state) => state.trades.closeTradeLoading);
  const format = useAppSelector((state) => state.main.activeFormat);

  const querySearch = new URLSearchParams(useLocation().search);

  const queryPage = querySearch.get(GLOBAL.page);
  const queryPerPage = querySearch.get(GLOBAL.per_page);

  const translation = useTranslation();
  const dispatch = useAppDispatch();

  const ref = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    dispatch(
      setShowCloseTrade({ isOpen: false, id: null, tab: "", activeFormat: "" })
    );
  };

  const handleConfirm = () => {
    dispatch(
      closeTrade({
        id: `${tradeId}`,
        data: { state: CLOSE_STATE },
        navigate: handleClose,
        tab,
        format,
        setDisableConfirm,
        page: `${queryPage}`,
        per_page: `${queryPerPage}`,
      })
    ).then(() => {
      setTimeout(() => {
        dispatch(setOpenToast(false));
      }, 3000);
    });
  };

  useOutsideClick({ ref, handler: handleClose });

  return (
    <ModalContainer>
      <div className="modal" ref={ref}>
        <div className="logout_header">
          <div className="close_modal">
            <img src={closeIcon} alt="Close" onClick={handleClose} />
          </div>
          <div className="logout_header_element">
            <img src={trashIcon} alt="Inactivate" className="logout_image" />
            <p className="logout_title">{translation.close_trade}</p>
          </div>
        </div>
        <div className="logout_body">
          <p className="logout_description">{translation.close_trade_text}</p>
        </div>
        <div className="logout_actions">
          <MainButton
            name={translation.yes}
            customClass="custom_button width_100"
            onClick={handleConfirm}
            loading={loading}
            disabled={loading || disableConfirm}
          />
          <MainButton
            name={translation.cancel}
            customClass="log_in_button width_100"
            onClick={handleClose}
            disabled={loading}
          />
        </div>
      </div>
    </ModalContainer>
  );
};
