import { Dispatch, SetStateAction } from "react";

export interface IBanner {
  banner: string;
}

export interface IAuth {
  [key: string]: string;
}

export interface ICreateRoom {
  userIds: string[];
  requestId: string;
}

export interface IAuthData {
  data: IAuth;
  navigate?: () => void;
  token?: string;
  id?: string;
  params?: IAuth;
  tab?: string;
  format?: string;
  setDisableConfirm?: Dispatch<SetStateAction<boolean>>;
}

export interface IPageOptions {
  page: string;
  per_page: string;
}

export type ITradeAction = IAuthData & IPageOptions;

export interface IDisableTwoFa {
  navigate: () => void;
  id: string;
  data: { token: string };
}

export interface IMerchantData {
  [key: string]: FormDataEntryValue;
}

export interface IPassportImageChange {
  data: IMerchantData;
  id: string;
}
export interface IMerchantAuth {
  data: IMerchantData;
  handleSubmitNavigate: () => void;
}

export interface IUserAuth {
  data: IMerchantData;
  navigate: () => void;
}

export interface IWallet {
  id: number;
  addressQrQode: string;
  currency_id: string;
  walletAddress: string;
  quantity: string;
  title: string;
  name: string;
  network_id: number;
  icon: string;
  currencyFullTitle: string;
}

export enum USER_STATUSES {
  verified = "Verified",
  notVerified = "NotVerified",
  pending = "Pending",
}
export interface IUser {
  id: number | null;
  email: string;
  firstName: string;
  lastName: string;
  nickname: string;
  role: string;
  isVerified: boolean;
  secret2FA: string | null;
  isEnabled2FA: boolean;
  wallets: IWallet[];
  telegramUserName: string;
  phoneNumber: string;
  balance: number | null;
  hasLoggedInBefore: boolean;
  passportImage: string[] | null;
  adminVerificationStatus: USER_STATUSES;
  kobbexCustomerId: string;
  isInactive: boolean;
}

export interface ITwoFa {
  qrCodeUrl: string;
  secret: string;
}

export interface IAuthSlice {
  user: IUser | null;
  userLoading: boolean;
  twoFaData: ITwoFa | null;
  showTwoFactor: boolean;
  changePasswordLoading: boolean;
  twoFaLoading: boolean;
  becomeMerchantLoading: boolean;
  forgotPasswordLoading: boolean;
  signUpLoading: boolean;
}

export interface IError {
  message: string;
  response: {
    status: number;
    data: { message: string; status: number };
  };
}

export interface ICardDelete {
  id: number;
  navigate: () => void;
  setDisableConfirm: Dispatch<SetStateAction<boolean>>;
}
