export const TRADES_TABS = [
  {
    id: 1,
    title: "buy",
    value: "buy",
  },
  {
    id: 2,
    title: "sell",
    value: "sell",
  },
];

export const ONGOING_TRADES_TABS = [
  {
    id: 1,
    title: "all",
    value: "",
  },
  {
    id: 2,
    title: "active",
    value: "active",
  },
  {
    id: 3,
    title: "inactive",
    value: "inactive",
  },
];

export const ONGOING_TRADES_USER_TABS = [
  {
    id: 1,
    title: "all",
    value: "",
  },
  {
    id: 2,
    title: "in_progress",
    value: "accepted",
  },
  {
    id: 3,
    title: "pending",
    value: "pending",
  },
];

export const BUTTON_ACTIONS: { [key: string]: string } = {
  active: "activate",
  inactive: "inactivate",
};

export const TRADE_STATUSES: { [key: string]: string } = {
  active: "active",
  inactive: "inactive",
};

export const CHANGE_TRADE_STATUS: { [key: string]: string } = {
  active: "inactive",
  inactive: "active",
};

export const CLOSE_STATE = "close";

export const TRADE_REQUESTS = [
  {
    id: 1,
    star: "3",
    requester_name: "Jen9n",
    requester_id: "2",
    trade_id: "1",
    user_status: "rejected",
    merchant_status: "rejected",
    amount: "321.123.345",
    created_at: "2024-06-25T10:45:04.711Z",
    updated_at: "2024-06-25T14:00:04.711Z",
  },
  {
    id: 2,
    star: "5",
    requester_name: "Jen9n",
    requester_id: "3",
    trade_id: "1",
    user_status: "closed",
    merchant_status: "closed",
    amount: "321.123.345",
    created_at: "2024-06-25T11:11:04.711Z",
    updated_at: "2024-06-25T11:11:04.711Z",
  },
];

export const DEFAULT_TIMER_MINUTES = 30;
export const DEFAULT_TIMER_MINUTES_STRING = "30:00";

export const TRADE_REQUEST_STATUSES = {
  accepted: "accepted",
  rejected: "rejected",
  pending: "pending",
  completed: "completed",
  closed: "closed",
};

export const CHAT_TRADE_REQUEST_STATUSES = [
  "accepted",
  "rejected",
  "completed",
];

export const GO_TO_CHAT_BUTTON_STATUSES = ["accepted", "completed"];

export const REQUEST_MERCHANT_STATUSES = ["rejected", "completed"];

export const IMAGES_COUNT = {
  desktop: 5,
  mobile: 3,
};

export const ARBITRAGE = "arbitrage";

export const REQUEST_DATA = {
  amount: "",
  id: null,
  merchant_status: "",
  requester: "",
  requester_id: "",
  requester_rating: null,
  trade_id: "",
  updated_at: "",
  created_at: "",
  user_status: "",
  currency: "",
  priceRate: "",
  type: "",
  is_arbitraged: false,
  roomId: null,
  merchant_id: null,
  currencyIcon: "",
  currencyTitle: "",
  currencyLongTitle: "",
  timer_updated_at: "",
  hasMessage: false,
  cardId: null,
  format: "",
  roomTitle: null,
  cardDetails: null,
};

export enum MESSAGE_NOTIFICATION_TYPES {
  ARBITRAGE = "arbitrage",
  COMPLETED = "completed",
  REJECTED = "rejected",
  ADMIN_COMPLETED = "adminCompleted",
  ADMIN_REJECTED = "adminRejected",
}

export enum TRADE_FORMAT_TYPES {
  ONLINE = "online",
  OFFLINE = "offline",
}
