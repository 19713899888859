import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useTranslation } from "localization";
import { RequestTimer } from "../components/tradeRequests/RequestTimer";
import {
  ARBITRAGE,
  DEFAULT_TIMER_MINUTES,
  MESSAGE_NOTIFICATION_TYPES,
  REQUEST_MERCHANT_STATUSES,
  TRADE_FORMAT_TYPES,
  TRADE_REQUEST_STATUSES,
} from "constants/trade";
import { AboutTrade, ChatSection } from "./components";
import { GLOBAL, USER_TYPES } from "constants/global";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { MainButton } from "components/buttons";
import {
  setShowPartiallyBlockModal,
  setTradeModalData,
} from "reduxState/features/main";
import { TradeRateModal } from "components/modals";
import { setOpenToast, setToastData } from "reduxState/features/toast";
import { TOAST_MESSAGES, TOAST_TYPES } from "constants/toast";
import { maskCardNumber } from "helpers/cardNumberTyping";

import backIcon from "assets/images/arrow-down.svg";
import starIcon from "assets/images/starIcon.svg";
import arbitrageIcon from "assets/images/chat/arbitrage.svg";
import arbitrageHoveredIcon from "assets/images/chat/arbitrageHovered.svg";
import inactivateIcon from "assets/images/trades/inactivatePopup.svg";
import activateIcon from "assets/images/trades/activatePopup.svg";
import arbitrageModalIcon from "assets/images/chat/arbitrageModal.svg";
import copyIcon from "assets/images/copy.svg";

const imageBase = process.env.REACT_APP_API_IMAGE_URL;

export const Chat = () => {
  const [disableArbitrage, setDisableArbitrage] = useState<boolean>(false);
  const [disableConfirmCancel, setDisableConfirmCancel] =
    useState<boolean>(false);

  const requestData = useAppSelector((state) => state.trades.userRequestData);
  const user = useAppSelector((state) => state.auth.user);
  const showUserRateModal = useAppSelector(
    (state) => state.trades.showUserRateModal
  );

  const isOnline = requestData.format === TRADE_FORMAT_TYPES.ONLINE;

  const maskedCard = maskCardNumber(requestData?.cardDetails?.cardNumber || "");

  const isMerchant = user?.role === USER_TYPES.merchant;
  const disableButtons = isMerchant
    ? REQUEST_MERCHANT_STATUSES.includes(requestData.merchant_status)
    : REQUEST_MERCHANT_STATUSES.includes(requestData.user_status);

  const disableArbitrageButton =
    requestData.merchant_status === MESSAGE_NOTIFICATION_TYPES.REJECTED;

  const dispatch = useAppDispatch();
  const translation = useTranslation();
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const requestId = query.get(GLOBAL.request_id);

  const date = new Date(requestData?.timer_updated_at);
  date.setMinutes(date.getMinutes() + DEFAULT_TIMER_MINUTES);

  const closeChat = () => {
    query.delete(GLOBAL.request_id);
    navigate(`?${query.toString()}`);
  };

  const handleClose = () => {
    if (user?.isInactive) {
      dispatch(setShowPartiallyBlockModal(true));
      return;
    }

    dispatch(
      setTradeModalData({
        title: isOnline ? translation.cancel_trade : translation.close_trade,
        description: isOnline
          ? translation.cancel_trade_message
          : translation.close_trade_popup_text,
        showTradeModal: true,
        icon: inactivateIcon,
        type: TRADE_REQUEST_STATUSES.rejected,
        id: `${requestId}`,
      })
    );
  };

  const handleConfirm = () => {
    if (user?.isInactive) {
      dispatch(setShowPartiallyBlockModal(true));
      return;
    }

    dispatch(
      setTradeModalData({
        title: translation.confirm_trade,
        description: translation.confirm_trade_text,
        showTradeModal: true,
        icon: activateIcon,
        type: TRADE_REQUEST_STATUSES.completed,
        id: `${requestId}`,
      })
    );
  };

  const handleArbitrage = () => {
    dispatch(
      setTradeModalData({
        title: translation.initiate_arbitrage,
        description: translation.initiate_arbitrage_message,
        showTradeModal: true,
        icon: arbitrageModalIcon,
        type: ARBITRAGE,
        id: `${requestId}`,
      })
    );
  };

  function fallbackCopyTextToClipboard(text: string) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");

      dispatch(
        setToastData({
          type: TOAST_TYPES.success,
          message: TOAST_MESSAGES.copy_card_number,
          open: true,
        })
      );
      setTimeout(() => {
        dispatch(setOpenToast(false));
      }, 3000);
    } catch (err) {
      return err;
    }

    document.body.removeChild(textArea);
  }

  const handleCopyHashCode = async () => {
    if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(
          requestData?.cardDetails?.cardNumber || ""
        );

        dispatch(
          setToastData({
            type: TOAST_TYPES.success,
            message: TOAST_MESSAGES.copy_card_number,
            open: true,
          })
        );
        setTimeout(() => {
          dispatch(setOpenToast(false));
        }, 3000);
      } catch (error) {
        return error;
      }
    } else {
      fallbackCopyTextToClipboard(requestData?.cardDetails?.cardNumber || "");
    }
  };

  useEffect(() => {
    if (!showUserRateModal) document.body.style.overflow = "unset";
  }, [showUserRateModal]);

  return (
    <div className="chat_container" id="chat_container_id">
      {showUserRateModal && (
        <TradeRateModal
          userId={
            isMerchant
              ? Number(requestData?.requester_id)
              : Number(requestData.merchant_id)
          }
        />
      )}
      <div
        className={`${isMerchant ? "mobile_padding" : ""} chat_information`}
        id="chat_information_id"
      >
        <div className="chat_header">
          <div className="right_side">
            <img
              src={backIcon}
              alt="Back"
              className="back_icon"
              onClick={closeChat}
            />
            <div className="user_info">
              {!!requestData?.requester_rating && (
                <div className="user_rating">
                  <img src={starIcon} alt="Star" className="star_icon" />
                  <p className="rating">{`${requestData?.requester_rating}/5`}</p>
                </div>
              )}
              <div className="user_name_section">
                <p className="user_name">{requestData?.requester}</p>
              </div>
            </div>
          </div>
          <div className="left_side">
            {!!requestData?.cardDetails?.id && (
              <div className="chat_cards_section">
                <img
                  src={`${imageBase}${requestData.cardDetails.image}`}
                  alt="Card"
                  className="card_icon"
                  crossOrigin="anonymous"
                />
                <p className="card_number">{maskedCard}</p>
                <img
                  src={copyIcon}
                  alt="Copy"
                  className="copy_icon"
                  onClick={handleCopyHashCode}
                />
              </div>
            )}
          </div>
        </div>
        <div className="trade_id_section">
          <p className="trade_info">{`${translation.trade_id} ${requestData?.trade_id}`}</p>
          {!(requestData?.is_arbitraged || disableArbitrage) && isOnline && (
            <div className="left_side">
              <RequestTimer
                date={date}
                customClass="chat_timer"
                isArbitraged={requestData?.is_arbitraged}
                elementId={requestData?.id}
              />
            </div>
          )}
        </div>
        <div className="chat_line"></div>
        <AboutTrade
          currencyTitle={requestData?.currencyTitle}
          currencyIcon={requestData?.currencyIcon}
          currencyLongTitle={requestData?.currencyLongTitle}
          amount={requestData?.amount}
          priceRate={requestData?.priceRate}
        />
        <div className="chat_actions">
          <div
            className={`arbitrage_field ${
              requestData?.is_arbitraged ||
              disableArbitrageButton ||
              disableArbitrage
                ? "disable_arbitrage_field"
                : ""
            }`}
          >
            <img
              src={arbitrageIcon}
              alt="Arbitrage"
              className={`arbitrage_icon ${
                requestData?.is_arbitraged ||
                disableArbitrageButton ||
                disableArbitrage
                  ? ""
                  : "arbitrage_icon_disabled"
              }`}
            />
            {!(
              requestData?.is_arbitraged ||
              disableArbitrageButton ||
              disableArbitrage
            ) && (
              <img
                src={arbitrageHoveredIcon}
                alt="Arbitrage"
                className="arbitrage_icon_hovered"
              />
            )}
            <button
              className="arbitrage"
              onClick={handleArbitrage}
              disabled={
                requestData?.is_arbitraged ||
                disableArbitrageButton ||
                disableArbitrage
              }
            >
              {translation.arbitrage}
            </button>
          </div>
          <div className="chat_action_buttons">
            {user?.role === USER_TYPES.merchant && (
              <MainButton
                name={isOnline ? translation.cancel : translation.close}
                customClass={`${
                  isOnline ? "log_in_button" : "custom_button"
                } chat_button`}
                onClick={handleClose}
                disabled={disableButtons || disableConfirmCancel}
              />
            )}
            {isOnline && (
              <MainButton
                name={translation.confirm}
                customClass="custom_button chat_button"
                onClick={handleConfirm}
                disabled={disableButtons || disableConfirmCancel}
              />
            )}
          </div>
        </div>
      </div>
      <ChatSection
        setDisableArbitrage={setDisableArbitrage}
        setDisableConfirmCancel={setDisableConfirmCancel}
      />
    </div>
  );
};
