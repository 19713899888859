import { Dispatch, FC, SetStateAction } from "react";

import { useTranslation } from "localization";
import { InfoElement } from "./InfoElement";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { IUserRequest } from "types";
import { setChatMessages } from "reduxState/features/trades";
import { formatDate } from "helpers";

interface IMobileTable {
  setSelectedRequest: Dispatch<SetStateAction<IUserRequest>>;
  setOpenChatHistory: Dispatch<SetStateAction<boolean>>;
}

export const MobileTable: FC<IMobileTable> = ({
  setSelectedRequest,
  setOpenChatHistory,
}) => {
  const translation = useTranslation();
  const dispatch = useAppDispatch();

  const data = useAppSelector((state) => state.trades.userRequests);

  const handleViewDetails = (transaction: IUserRequest) => {
    setSelectedRequest(transaction);
    setOpenChatHistory(true);
    dispatch(setChatMessages([]));
  };

  return (
    <div className="mobile_table_container" id="mobile_table_container">
      {data?.map((transaction) => (
        <div className="trade_mobile_section" key={transaction.id}>
          <div className="trade_information">
            <div className="trade_elements_title">
              <InfoElement
                title={translation.deal_id}
                name={`${transaction.id}`}
              />
              <InfoElement
                title={translation.currency}
                name={transaction?.currencyTitle}
                fullName={transaction?.currencyLongTitle}
                icon={transaction?.currencyIcon}
              />
              <InfoElement
                title={translation.crypto_coin}
                name={transaction?.amount}
              />
              <InfoElement
                title={translation.usd_to_amd}
                name={transaction?.priceRate}
                isIconEnd
              />
              <InfoElement
                title={translation.status}
                name={transaction?.merchant_status}
                isStatus={true}
              />
              <InfoElement
                title={translation.date}
                name={formatDate({ date: transaction?.created_at })}
              />
              <InfoElement
                title={translation.action}
                name={translation.view_details}
                isAction={true}
                handleViewDetails={() => handleViewDetails(transaction)}
                disabled={!transaction?.hasMessage}
              />
            </div>
          </div>
        </div>
      ))}
      <div></div>
    </div>
  );
};
