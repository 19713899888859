import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import {
  FieldValues,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";

import { useAppSelector } from "reduxState/store";

import arrowIcon from "assets/images/arrow-down-grey.svg";

interface ISelectItem {
  handleCloseDropdown: () => void;
  setValue: UseFormSetValue<FieldValues>;
  name: string;
  openSelect: boolean;
  setOpenSelect: Dispatch<SetStateAction<boolean>>;
  getValues: UseFormGetValues<FieldValues>;
  isError: boolean;
  register: UseFormRegister<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  isSubmitted: boolean;
  setSelectedCurrencyShortName?: Dispatch<SetStateAction<string>>;
  isWalletSteps?: boolean;
  handleAction?: () => void;
}

const imageUrl = process.env.REACT_APP_API_IMAGE_URL;

export const SelectItem: FC<ISelectItem> = ({
  handleCloseDropdown,
  setValue,
  name,
  openSelect,
  setOpenSelect,
  getValues,
  isError,
  register,
  clearErrors,
  setError,
  isSubmitted,
  setSelectedCurrencyShortName,
  isWalletSteps,
  handleAction,
}) => {
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const [selectedItemName, setSelectedItemName] = useState<string>("");

  const data = useAppSelector((state) => state.banksCurrencies.currencies);

  const handleSelect = (
    id: number,
    value: string,
    shortTitle: string,
    networkName: string
  ) => {
    if (id === selectedItemId) {
      setSelectedItemId(null);
      setValue(name, "");

      if (isWalletSteps) {
        setValue("chain", "");
        setValue("currencyId", "");
      } else {
        setValue("amount", "");
      }

      setSelectedItemName("");

      if (setSelectedCurrencyShortName) setSelectedCurrencyShortName("");

      if (isSubmitted) {
        setError(name, {
          type: "required",
          message: "Required",
        });
      }
    } else {
      setSelectedItemName(value);

      if (isWalletSteps) {
        setValue(name, value);
        setValue("chain", networkName);
        setValue("currencyId", id);
      } else {
        setValue(name, id);
      }

      if (setSelectedCurrencyShortName)
        setSelectedCurrencyShortName(shortTitle);
      setSelectedItemId(id);
      clearErrors(name);
    }

    handleCloseDropdown();
    if (handleAction) handleAction();
  };

  const handleShowDropdown = () => {
    setOpenSelect(!openSelect);
  };

  useEffect(() => {
    if (!getValues(name)) {
      setSelectedItemName("");
      setSelectedItemId(null);
    }
  }, [getValues(name)]);

  return (
    <div className="input_filed_content">
      <input
        {...register(name, {
          required: true,
        })}
        className="select_input"
        id={name}
        name={name}
      />
      <div
        className={`custom_select_box ${isError ? "select_error" : ""} ${
          openSelect ? "select_box_open" : ""
        }`}
        onClick={handleShowDropdown}
      >
        <p className="select_item_name">{selectedItemName}</p>
        <img
          src={arrowIcon}
          alt="Search"
          className={
            openSelect ? "rotate_select_adornment" : "select_adornment"
          }
        />
      </div>
      {openSelect && (
        <div className="select_dropdown">
          {data.map((element) => (
            <div
              className={`select_element ${
                selectedItemId === element.id ? "selected_element" : ""
              }`}
              onClick={() =>
                handleSelect(
                  element.id,
                  element.name,
                  element.name,
                  element.networkName
                )
              }
            >
              <div className="select_left_section">
                <img
                  src={`${imageUrl}${element.icon}`}
                  alt="Icon"
                  className="element_icon"
                  crossOrigin="anonymous"
                />
                <div className="element_name_field">
                  <p className="element_name">{element.currencyFullTitle}</p>
                  <span className="currency_line"></span>
                  <p className="element_name">{element.name}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
