import {
  Dispatch,
  FC,
  KeyboardEvent,
  MouseEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import { useLocation, useNavigate } from "react-router-dom";

import { useTranslation } from "localization";
import { ImagesGrid } from "./UploadImage";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { ChatMessages } from "./ChatMessages";
import {
  CHAT_SECTION_GAP,
  FILE_MAX_SIZE,
  GLOBAL,
  KEY_NAMES,
} from "constants/global";
import { socket } from "socket";
import { getChatMessages } from "reduxState";
import { IChatMessages } from "types";
import {
  setIsChatArbitraged,
  setIsConfirmCancelRequest,
} from "reduxState/features/trades";
import {
  MESSAGE_NOTIFICATION_TYPES,
  REQUEST_MERCHANT_STATUSES,
} from "constants/trade";
import { setOpenToast, setToastData } from "reduxState/features/toast";
import { TOAST_TYPES } from "constants/toast";

import sendIcon from "assets/images/chat/send.svg";
import uploadIcon from "assets/images/chat/upload.svg";

interface IChatSection {
  setDisableArbitrage: Dispatch<SetStateAction<boolean>>;
  setDisableConfirmCancel: Dispatch<SetStateAction<boolean>>;
}

export const ChatSection: FC<IChatSection> = ({
  setDisableArbitrage,
  setDisableConfirmCancel,
}) => {
  const [chatValue, setChatValue] = useState<string>("");
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [isMessageSend, setIsMessageSend] = useState<boolean>(false);

  const user = useAppSelector((state) => state.auth.user);
  const roomId = useAppSelector((state) => state.trades.roomId);
  const roomTitle = useAppSelector((state) => state.trades.roomTitle);
  const messagesList = useAppSelector((state) => state.trades.messages);
  const messageLoading = useAppSelector(
    (state) => state.trades.messagesLoading
  );
  const isChatArbitraged = useAppSelector(
    (state) => state.trades.isChatArbitraged
  );
  const isConfirmCancelRequest = useAppSelector(
    (state) => state.trades.isConfirmCancelRequest
  );
  const requestData = useAppSelector((state) => state.trades.userRequestData);

  const navigate = useNavigate();
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const query = new URLSearchParams(useLocation().search);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "auto",
    });
    setIsMessageSend(false);
  };

  const handleOnFocus = () => {
    if (textareaRef.current) {
      textareaRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const [messages, setMessages] = useState<IChatMessages[]>(messagesList);

  const translation = useTranslation();
  const dispatch = useAppDispatch();

  const textArea = document.getElementById("chat_input_id");

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const oversizedFiles = acceptedFiles.filter(
      (file) => file.size > FILE_MAX_SIZE
    );

    if (oversizedFiles.length > 0) {
      dispatch(
        setToastData({
          type: TOAST_TYPES.error,
          message: translation.big_image_error_msg,
          open: true,
        })
      );

      setTimeout(() => {
        dispatch(setOpenToast(false));
      }, 3000);
      return;
    }

    setUploadedFiles((prev) => [...prev, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpg": [".jpg"],
      "image/jpeg": [".jpeg"],
      "image/webp": [".webp"],
    },
    disabled:
      requestData.merchant_status === MESSAGE_NOTIFICATION_TYPES.REJECTED ||
      (requestData.merchant_status === MESSAGE_NOTIFICATION_TYPES.COMPLETED &&
        requestData.user_status === MESSAGE_NOTIFICATION_TYPES.COMPLETED),
  });

  const handleSend = async (
    e:
      | MouseEvent<HTMLElement>
      | KeyboardEvent<HTMLTextAreaElement>
      | KeyboardEvent<HTMLElement>
  ) => {
    e.stopPropagation();

    const readFileAsArrayBuffer = (file: File) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (event) {
          resolve({ fileName: file.name, fileData: event.target?.result });
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
    };
    Promise.all(uploadedFiles.map(readFileAsArrayBuffer))
      .then((images) => {
        const data = {
          room_id: Number(roomId),
          roomTitle: roomTitle,
          sender_id: user?.id,
          message: chatValue,
          created_at: new Date(),
          attachments: images,
          is_admin: false,
          is_arbitraged: false,
          request_state: "",
          sender_name: user?.nickname
            ? user.nickname
            : `${user?.firstName} ${user?.lastName}`,
          admin_action: null,
        };
        const messageData = {
          room_id: Number(roomId),
          roomTitle: roomTitle,
          sender_id: user?.id,
          message: chatValue,
          created_at: new Date(),
          attachmentPaths: uploadedFiles,
          is_admin: false,
          is_arbitraged: false,
          request_state: "",
          sender_name: user?.nickname
            ? user.nickname
            : `${user?.firstName} ${user?.lastName}`,
          admin_action: null,
        };

        socket.emit("send_message", data);
        setMessages([...messages, messageData]);
        setIsMessageSend(true);
      })
      .catch((error) => {
        console.error("Error reading files:", error);
      });

    setChatValue("");
    setUploadedFiles([]);
  };

  useEffect(() => {
    if (textArea) {
      if (textArea.scrollHeight > 76) {
        textArea.style.height = "76px";
      } else {
        textArea.style.height = "auto";
      }
    }
  }, [chatValue]);
  useEffect(() => {
    socket.emit("joinRoom", roomTitle);

    socket.on("receive_message", (data) => {
      if (data?.roomTitle === requestData?.roomTitle) {
        if (data?.is_arbitraged) {
          setDisableArbitrage(true);
        }

        if (data.request_state === MESSAGE_NOTIFICATION_TYPES.REJECTED) {
          setDisableConfirmCancel(true);
          setDisableArbitrage(true);

          setTimeout(() => {
            query.delete(GLOBAL.request_id);
            navigate(`?${query.toString()}`);
          }, 15000);
        }

        if (REQUEST_MERCHANT_STATUSES.includes(data?.admin_action)) {
          setDisableConfirmCancel(true);
        }

        setMessages((prev) => [...prev, data]);
      }
    });

    socket.on("error", (error) => {});

    return () => {
      socket.off("receive_message");
      socket.off("error");
    };
  }, [roomTitle]);

  useEffect(() => {
    if (roomId) dispatch(getChatMessages({ id: roomId }));
  }, [roomId]);

  useEffect(() => {
    setMessages(messagesList);
  }, [messagesList]);

  useEffect(() => {
    if (isChatArbitraged) {
      const data = {
        room_id: Number(roomId),
        roomTitle: roomTitle,
        sender_id: user?.id,
        message: "",
        created_at: new Date(),
        attachments: [],
        is_admin: false,
        is_arbitraged: true,
        request_state: "",
        sender_name: user?.nickname
          ? user.nickname
          : `${user?.firstName} ${user?.lastName}`,
        admin_action: null,
      };
      const messageData = {
        room_id: Number(roomId),
        roomTitle: roomTitle,
        sender_id: user?.id,
        message: "",
        created_at: new Date(),
        attachmentPaths: [],
        is_admin: false,
        is_arbitraged: true,
        request_state: "",
        sender_name: user?.nickname
          ? user.nickname
          : `${user?.firstName} ${user?.lastName}`,
        admin_action: null,
      };
      socket.emit("send_message", data);
      setDisableArbitrage(true);
      setMessages([...messages, messageData]);
      dispatch(setIsChatArbitraged(false));
    }
  }, [isChatArbitraged]);

  useEffect(() => {
    if (isConfirmCancelRequest) {
      const data = {
        room_id: Number(roomId),
        roomTitle: roomTitle,
        sender_id: user?.id,
        message: "",
        created_at: new Date(),
        attachments: [],
        is_admin: false,
        is_arbitraged: false,
        request_state: isConfirmCancelRequest,
        sender_name: user?.nickname
          ? user.nickname
          : `${user?.firstName} ${user?.lastName}`,
        admin_action: null,
      };
      const messageData = {
        room_id: Number(roomId),
        roomTitle: roomTitle,
        sender_id: user?.id,
        message: "",
        created_at: new Date(),
        attachmentPaths: [],
        is_admin: false,
        is_arbitraged: false,
        request_state: isConfirmCancelRequest,
        sender_name: user?.nickname
          ? user.nickname
          : `${user?.firstName} ${user?.lastName}`,
        admin_action: null,
      };
      socket.emit("send_message", data);
      setDisableConfirmCancel(true);
      setMessages([...messages, messageData]);
      dispatch(setIsConfirmCancelRequest(""));
      if (isConfirmCancelRequest === MESSAGE_NOTIFICATION_TYPES.REJECTED) {
        setDisableArbitrage(true);
      }
    }
  }, [isConfirmCancelRequest]);

  useEffect(() => {
    const chatContainer = document
      .getElementById("chat_container_id")
      ?.getBoundingClientRect()?.height;
    const chatInformation = document
      .getElementById("chat_information_id")
      ?.getBoundingClientRect()?.height;
    const chatActionsField = document
      .getElementById("chat_actions_field_id")
      ?.getBoundingClientRect()?.height;
    const chat_messages_section_id = document.getElementById(
      "chat_messages_section_id"
    );

    if (
      chat_messages_section_id &&
      chatContainer &&
      chatInformation &&
      chatActionsField
    )
      chat_messages_section_id.style.maxHeight =
        chatContainer -
        (chatInformation + chatActionsField + CHAT_SECTION_GAP) +
        "px";
  }, [uploadedFiles]);

  useEffect(() => {
    if (isMessageSend) setTimeout(() => scrollToBottom(), 0);
  }, [messages, isMessageSend]);

  useEffect(() => {
    if (!messageLoading) {
      setTimeout(() => scrollToBottom(), 0);
    }
  }, [messageLoading, messages]);

  useEffect(() => {
    if (isConfirmCancelRequest === MESSAGE_NOTIFICATION_TYPES.REJECTED) {
      setTimeout(() => {
        query.delete(GLOBAL.request_id);
        navigate(`?${query.toString()}`);
      }, 15000);
    }
  }, [isConfirmCancelRequest]);

  return (
    <div className="chat_section">
      <ChatMessages messages={messages} messagesEndRef={messagesEndRef} />
      <div className="chat_actions_field" id="chat_actions_field_id">
        <div
          className="chat_input_field"
          {...getRootProps()}
          onKeyDown={(event) => {
            if (event.key == KEY_NAMES.enter && !event.shiftKey) {
              event.preventDefault();
              if (chatValue.trim().length || uploadedFiles.length) {
                handleSend(event);
              }
              return;
            }
          }}
        >
          <div className="chat_input" onClick={(e) => e.stopPropagation()}>
            <textarea
              ref={textareaRef}
              className="chat_input"
              id="chat_input_id"
              value={chatValue}
              onChange={(e) => {
                if (e.target.value !== " ") setChatValue(e.target.value);
              }}
              placeholder={translation.type_message}
              onFocus={handleOnFocus}
              onKeyDown={(event) => {
                if (event.key == KEY_NAMES.enter && !event.shiftKey) {
                  event.preventDefault();
                  if (chatValue.trim().length || uploadedFiles.length) {
                    handleSend(event);
                  }
                  return;
                }
              }}
            />
            <div className="uploaded_files">
              <input {...getInputProps()} className="upload_file_drag" />
              {!!uploadedFiles?.length && (
                <ImagesGrid value={uploadedFiles} setValue={setUploadedFiles} />
              )}
            </div>
          </div>
          <div className="chat_send_upload">
            <button className="chat_send_upload_button">
              <img src={uploadIcon} className="action_icon" alt="Upload" />
            </button>
            <button
              className="chat_send_upload_button"
              onClick={handleSend}
              disabled={
                (!chatValue.trim().length && !uploadedFiles.length) ||
                requestData.merchant_status ===
                  MESSAGE_NOTIFICATION_TYPES.REJECTED ||
                (requestData.merchant_status ===
                  MESSAGE_NOTIFICATION_TYPES.COMPLETED &&
                  requestData.user_status ===
                    MESSAGE_NOTIFICATION_TYPES.COMPLETED)
              }
            >
              <img src={sendIcon} className="action_icon" alt="Send" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
