import { Dispatch, FC, SetStateAction, useState } from "react";

import { useAppSelector } from "reduxState/store";

import arrowIcon from "assets/images/arrow-down-grey.svg";

interface ISelectItem {
  handleCloseDropdown: () => void;
  name: string;
  openSelect: boolean;
  setOpenSelect: Dispatch<SetStateAction<boolean>>;
  setSelectedCurrencyShortName: Dispatch<SetStateAction<string>>;
  label: string;
  selectedCurrencyShortName: string;
}

const imageUrl = process.env.REACT_APP_API_IMAGE_URL;

export const SelectBalanceCurrency: FC<ISelectItem> = ({
  handleCloseDropdown,
  name,
  openSelect,
  setOpenSelect,
  setSelectedCurrencyShortName,
  label,
  selectedCurrencyShortName,
}) => {
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const [selectedItemName, setSelectedItemName] = useState<string>("");

  const data = useAppSelector((state) => state.banksCurrencies.currencies);

  const handleSelect = (id: number, value: string, shortTitle: string) => {
    if (id === selectedItemId) {
      setSelectedItemId(null);
      setSelectedItemName("");

      setSelectedCurrencyShortName("");
    } else {
      setSelectedItemName(value);
      setSelectedCurrencyShortName(shortTitle);
      setSelectedItemId(id);
    }

    handleCloseDropdown();
  };

  const handleShowDropdown = () => {
    setOpenSelect(!openSelect);
  };

  return (
    <div className="input_filed_content">
      <input className="select_input" id={name} name={name} />
      <div
        className={`custom_select_box ${openSelect ? "select_box_open" : ""}`}
        onClick={handleShowDropdown}
      >
        <p className="select_item_name">
          {selectedItemName || selectedCurrencyShortName || label}
        </p>
        <img
          src={arrowIcon}
          alt="Search"
          className={
            openSelect ? "rotate_select_adornment" : "select_adornment"
          }
        />
      </div>
      {openSelect && (
        <div className="select_dropdown">
          {data.map((element) => (
            <div
              className={`select_element ${
                selectedItemId === element.id ? "selected_element" : ""
              }`}
              onClick={() =>
                handleSelect(
                  element.id,
                  element.currencyFullTitle,
                  element.name
                )
              }
            >
              <div className="select_left_section">
                <img
                  src={`${imageUrl}${element.icon}`}
                  alt="Icon"
                  className="element_icon"
                  crossOrigin="anonymous"
                />
                <div className="element_name_field">
                  <p className="element_name">{element.currencyFullTitle}</p>
                  <span className="currency_line"></span>
                  <p className="element_name">{element.name}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
