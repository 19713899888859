import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useTranslation } from "localization";
import { LanguageDropdown, MainButton, ProfileDropdown } from "components";
import { COUNTRIES, DEFAULT_LANGUAGE, LANGUAGE } from "constants/header";
import { GLOBAL, PATHNAMES, USER_TYPES } from "constants/global";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import {
  setShowCreateTrade,
  setShowMobileMenu,
  setShowPartiallyBlockModal,
} from "reduxState/features/main";
import useOutsideClick from "hooks/useOutsideClick";

import arrowDownIcon from "assets/images/arrow-down.svg";
import avatar from "assets/images/avatar.svg";
import wallet from "assets/images/wallet.svg";
import supportIcon from "assets/images/support.png";
import hamburgerMenu from "assets/images/hamburgerMenu.png";

export const RightSide = () => {
  const [openLanguages, setOpenLanguages] = useState<boolean>(false);
  const [openUserInfo, setOpenUserInfo] = useState<boolean>(false);
  const [userType, setUserType] = useState<string>(USER_TYPES.guest);
  const [isUser, setIsUser] = useState<boolean>(false);

  const languageRef = useRef<HTMLDivElement>(null);
  const profileRef = useRef<HTMLDivElement>(null);

  const user = useAppSelector((state) => state.auth.user);

  const translation = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const language = localStorage.getItem(LANGUAGE) || DEFAULT_LANGUAGE;

  const selectedLanguage =
    COUNTRIES.find((elem) => elem.prefix === language) || COUNTRIES[0];

  const handleOpenLanguages = () => {
    setOpenLanguages(!openLanguages);
  };

  const handleCloseLanguages = () => {
    setOpenLanguages(false);
  };

  const handleOpenUserInfo = () => {
    setOpenUserInfo(!openUserInfo);
  };

  const handleCloseUserInfo = () => {
    setOpenUserInfo(false);
  };

  const handleOpenMenu = () => {
    document.body.style.overflow = "hidden";
    dispatch(setShowMobileMenu(true));
  };

  const handleRoute = (path: string) => {
    navigate(path);
  };

  const openCreateTrade = () => {
    if (user?.isInactive) {
      dispatch(setShowPartiallyBlockModal(true));
      return;
    }
    dispatch(setShowCreateTrade(true));
  };

  const handleRouteWallet = () => {
    navigate(PATHNAMES.wallet);
  };

  const redirectToTelegram = () => {
    window.open(GLOBAL.telegram_link, "_blank");
  };

  useEffect(() => {
    setUserType(!!user ? user?.role : USER_TYPES.guest);
    setIsUser(!!user);
  }, [user]);

  useOutsideClick({ ref: languageRef, handler: handleCloseLanguages });
  useOutsideClick({ ref: profileRef, handler: handleCloseUserInfo });

  return (
    <>
      <div className="desktop_header_actions">
        <div className="header_right_side">
          {isUser && (
            <div
              className="header_element gap_4 wallet_element"
              onClick={handleRouteWallet}
            >
              <img src={wallet} alt="Wallet" />
              <p className="element_name">{translation.my_wallet}</p>
            </div>
          )}
          <div
            className="header_element"
            onClick={handleOpenLanguages}
            ref={languageRef}
          >
            <img src={selectedLanguage.image} alt="Flag" />
            <img
              src={arrowDownIcon}
              alt="Arrow"
              className={`${openLanguages ? "rotate_icon" : "arrow_icon"}`}
            />
            {openLanguages && <LanguageDropdown />}
          </div>
          {!isUser && (
            <>
              <MainButton
                name={translation.login}
                onClick={() => handleRoute(PATHNAMES.sign_in)}
                customClass="log_in_button"
              />
              <MainButton
                name={translation.sign_up}
                onClick={() => handleRoute(PATHNAMES.sign_up)}
                customClass="custom_button"
              />
            </>
          )}
          {isUser && userType === USER_TYPES.merchant && (
            <MainButton
              name={translation.create_trade}
              onClick={openCreateTrade}
              customClass="custom_button"
            />
          )}
          <MainButton
            name={translation.support}
            onClick={redirectToTelegram}
            customClass="custom_button"
          />
          {isUser && (
            <div
              className="header_element"
              onClick={handleOpenUserInfo}
              ref={profileRef}
            >
              <img src={avatar} alt="Flag" className="avatar_icon" />
              <img
                src={arrowDownIcon}
                alt="Arrow"
                className={`${openUserInfo ? "rotate_icon" : "arrow_icon"}`}
              />
              {openUserInfo && <ProfileDropdown />}
            </div>
          )}
        </div>
      </div>
      <div className="mobile_header_actions">
        <div className="header_right_side">
          <img
            src={supportIcon}
            alt="Support"
            className="support_icon"
            onClick={redirectToTelegram}
          />
          <button className="mobile_menu_button" onClick={handleOpenMenu}>
            <img src={hamburgerMenu} alt="Menu" className="hamburger_menu" />
          </button>
        </div>
      </div>
    </>
  );
};
