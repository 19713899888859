import { Dispatch, FC, SetStateAction, useEffect } from "react";

import { useTranslation } from "localization";
import { DEFAULT_TIMER_MINUTES } from "constants/trade";
import { AboutTrade, ChatSection } from "./components";
import { ModalContainer } from "components/modals/ModalAuth";
import { IUserRequest } from "types";
import { useAppDispatch } from "reduxState/store";
import { getChatMessages } from "reduxState";
import { CHAT_POPUP_SECTION_GAP } from "constants/global";
import { setChatMessages } from "reduxState/features/trades";

import closeIcon from "assets/images/close.svg";

interface IChat {
  requestData: IUserRequest;
  setOpenChatHistory: Dispatch<SetStateAction<boolean>>;
}

export const Chat: FC<IChat> = ({ requestData, setOpenChatHistory }) => {
  const translation = useTranslation();
  const dispatch = useAppDispatch();

  const date = new Date(requestData.updated_at);
  date.setMinutes(date.getMinutes() + DEFAULT_TIMER_MINUTES);

  const handleClosePopup = () => {
    setOpenChatHistory(false);
  };

  useEffect(() => {
    if (requestData.roomId) {
      dispatch(getChatMessages({ id: `${requestData.roomId}` }));
    } else {
      dispatch(setChatMessages([]));
    }
  }, [requestData.roomId]);

  useEffect(() => {
    const chatContainer = document
      .getElementById("chat_popup_id")
      ?.getBoundingClientRect()?.height;
    const chatInformation = document
      .getElementById("chat_popup_information_id")
      ?.getBoundingClientRect()?.height;
    const chatHeader = document
      .getElementById("chat_header_id")
      ?.getBoundingClientRect()?.height;
    const chat_messages_section_id = document.getElementById(
      "chat_popup_messages_section_id"
    );

    if (
      chat_messages_section_id &&
      chatContainer &&
      chatInformation &&
      chatHeader
    )
      chat_messages_section_id.style.maxHeight =
        chatContainer -
        (chatInformation + chatHeader + CHAT_POPUP_SECTION_GAP) +
        "px";
  }, []);

  return (
    <ModalContainer>
      <div className="chat_popup" id="chat_popup_id">
        <div className="chat_popup_header" id="chat_header_id">
          <img
            className="close_icon"
            alt="Close"
            src={closeIcon}
            onClick={handleClosePopup}
          />
          <p className="trade_details_title">{translation.trade_details}</p>
        </div>
        <div className="chat_container ">
          <div className="chat_information" id="chat_popup_information_id">
            <div className="chat_header">
              <div className="right_side">
                <div className="user_info">
                  <div className="user_name_section">
                    <p className="user_name">{`${translation.id} ${requestData.trade_id}`}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="chat_line"></div>
            <AboutTrade
              currencyTitle={requestData?.currencyTitle}
              currencyIcon={requestData?.currencyIcon}
              currencyLongTitle={requestData?.currencyLongTitle}
              amount={requestData.amount}
              priceRate={requestData.priceRate}
            />
          </div>
          <ChatSection requestData={requestData} />
        </div>
      </div>
    </ModalContainer>
  );
};
