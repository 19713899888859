import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { PageLoading, Pagination } from "components";
import { DesktopTable } from "./desktoptable/DesktopTable";
import { MobileTable } from "./mobileTable/MobileTable";
import { GLOBAL, MOBILE_WINDOW_WIDTH } from "constants/global";
import { IBalanceParamsObj, IMainTableElement, ITableFilters } from "types";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { getBalanceHistory } from "reduxState";
import { EmptyTablePage } from "../../EmptyPage";
import { useTranslation } from "localization";

interface ITransactionTable {
  headerData: IMainTableElement[];
  filters: ITableFilters;
  selectedCurrency: string;
}

export const TableBody: FC<ITransactionTable> = ({
  headerData,
  filters,
  selectedCurrency,
}) => {
  const querySearch = new URLSearchParams(useLocation().search);
  const queryPage = querySearch.get(GLOBAL.page);
  const queryPerPage = querySearch.get(GLOBAL.per_page);

  const [page, setPage] = useState<number>(
    queryPage ? Number(queryPage) : GLOBAL.default_page
  );
  const [perPage, setPerPage] = useState<number>(
    queryPerPage ? Number(queryPerPage) : GLOBAL.default_per_page
  );

  const total = useAppSelector((state) => state.userData.balanceTotal);
  const data = useAppSelector((state) => state.userData.balanceHistory);
  const loading = useAppSelector((state) => state.userData.balanceLoading);

  const dispatch = useAppDispatch();
  const translation = useTranslation();

  const handleScrollToTop = () => {
    if (window.innerWidth > MOBILE_WINDOW_WIDTH) {
      window?.scrollTo({
        behavior: "smooth",
        top: 0,
      });
    } else {
      const accountSection = document
        .getElementById("profile_menu")
        ?.getBoundingClientRect();

      const mobileTableContainer = document.getElementById(
        "mobile_table_container"
      );

      const accountSectionHeight = accountSection?.height;

      window?.scrollTo({
        behavior: "smooth",
        top: accountSectionHeight,
      });

      mobileTableContainer?.scrollTo({
        top: 0,
      });
    }
  };

  useEffect(() => {
    const params: IBalanceParamsObj = {
      page,
      perPage: perPage,
      transactionType: filters?.transactionType,
      currency: selectedCurrency,
    };

    dispatch(getBalanceHistory({ params }));
  }, [filters?.transactionType, page, perPage, selectedCurrency]);

  useEffect(() => {
    if (queryPage && Number(queryPage) !== page) {
      setPage(Number(queryPage));
    }
  }, [queryPage]);

  return (
    <div className="table_body">
      {loading ? (
        <PageLoading loading={loading} customClass="table_loading" />
      ) : (
        <>
          {!!data?.length ? (
            <>
              <DesktopTable headerData={headerData} />
              <MobileTable />
            </>
          ) : (
            <EmptyTablePage
              filters={filters}
              message={translation.no_balance_history}
            />
          )}

          <div className="table_pagination">
            <Pagination
              perPage={perPage}
              total={total}
              page={page}
              setPage={setPage}
              setPerPage={setPerPage}
              handleAction={handleScrollToTop}
            />
          </div>
        </>
      )}
    </div>
  );
};
