import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import validator from "validator";
import {
  FieldValues,
  SubmitHandler,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
  useForm,
} from "react-hook-form";

import { SingleCard } from "./SingleCard";
import { MainButton } from "../buttons";
import { TextInput } from "../inputs";

import { useTranslation } from "localization";

import { GLOBAL, MIN_MAX_CHARACTERS } from "constants/global";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { createCard, getCards } from "reduxState";
import { setOpenToast } from "reduxState/features/toast";
import { CARD_PLACEHOLDER } from "constants/profile";
import { ICard } from "types";

import noCardIcon from "assets/images/noCard.svg";
import dangerIcon from "assets/images/danger.svg";

interface ITradeCards {
  selectedCardId: number | null;
  setSelectedCardId: Dispatch<SetStateAction<number | null>>;
  isError: boolean;
  name: string;
  tradeRegister: UseFormRegister<FieldValues>;
  setTradeCardValue: UseFormSetValue<FieldValues>;
  clearFormErrors: UseFormClearErrors<FieldValues>;
  defaultCard: ICard | undefined;
}

export const TradeCreateCards: FC<ITradeCards> = ({
  selectedCardId,
  setSelectedCardId,
  isError,
  name,
  tradeRegister,
  setTradeCardValue,
  clearFormErrors,
  defaultCard,
}) => {
  const [isOpenCardAdd, setIsOpenCardAdd] = useState<boolean>(false);
  const [showAllCards, setShowAllCards] = useState<boolean>(false);
  const translation = useTranslation();

  const cards = useAppSelector((state) => state.cardsData.cards);
  const loading = useAppSelector((state) => state.cardsData.loading);

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();

  const dispatch = useAppDispatch();

  const handleChooseCard = (id: number) => {
    setSelectedCardId(id);
    setTradeCardValue(name, id);
    clearFormErrors(name);
    setShowAllCards(false);
  };

  const handleOpenForm = () => {
    setIsOpenCardAdd(true);
  };

  const handleOpenCards = () => {
    setShowAllCards(true);
  };

  const onSubmit: SubmitHandler<FieldValues> = (data, event) => {
    const cardNumber = validator.isCreditCard(data.cardNumber);
    if (!cardNumber) {
      setError("cardNumber", {
        type: GLOBAL.custom,
        message: translation.enter_valid_card,
      });
      return;
    }

    const withoutSpacesCardNumber = data.cardNumber.replace(/\s+/g, "");

    const validData = { ...data, cardNumber: withoutSpacesCardNumber };

    dispatch(createCard({ data: validData })).finally(() => {
      setTimeout(() => {
        dispatch(setOpenToast(false));
      }, 3000);
    });
  };

  useEffect(() => {
    dispatch(getCards());
  }, []);

  return (
    <div className="create_trade_cards">
      <input
        {...tradeRegister(name, {
          required: true,
        })}
        className="select_input"
        id={name}
        name={name}
      />
      <div className="cards_section">
        <div className="cards_section_header">
          <p className="my_cards">{translation.my_cards_mandatory}</p>
          {cards?.length > 1 && (
            <button
              className="change_card_button"
              type="button"
              onClick={handleOpenCards}
            >
              {translation.change_card}
            </button>
          )}
        </div>
        {cards?.length ? (
          showAllCards ? (
            <div className="trade_cards_grid">
              {cards.map((card) => (
                <SingleCard
                  key={card.id}
                  cardHolder={card.cardHolder}
                  cardNumber={card.cardNumber}
                  icon={card.image}
                  id={card.id}
                  customClass="trade_modal_cards"
                  isHaveCheckMark={true}
                  isChecked={
                    selectedCardId ? card.id === selectedCardId : card.isDefault
                  }
                  handleClick={handleChooseCard}
                />
              ))}
            </div>
          ) : defaultCard ? (
            <SingleCard
              key={defaultCard.id}
              cardHolder={defaultCard.cardHolder}
              cardNumber={defaultCard.cardNumber}
              icon={defaultCard.image}
              id={defaultCard.id}
              customClass="trade_modal_cards"
              isHaveCheckMark={true}
              isChecked={true}
              handleClick={handleChooseCard}
            />
          ) : (
            <></>
          )
        ) : isOpenCardAdd ? (
          <div className="card_empty_section_container">
            <form
              className={`card_add_form ${isError ? "error_cards_form" : ""}`}
            >
              <div className="card_form_inputs">
                <TextInput
                  name="cardNumber"
                  label={translation.card_number}
                  type="text"
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  placeholder={CARD_PLACEHOLDER}
                  isNumber
                  isCardNumber
                  clearErrors={clearErrors}
                  watch={watch}
                />
                <TextInput
                  name="cardHolder"
                  label={translation.card_name}
                  type="text"
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  placeholder={translation.name_surname}
                  pattern={MIN_MAX_CHARACTERS}
                  patternError={translation.min_max_character_count}
                />
              </div>
              <MainButton
                name={translation.save}
                customClass="log_in_button width_100"
                type="button"
                onClick={handleSubmit(onSubmit)}
                loading={loading}
                disabled={loading}
              />
            </form>
            {isError && (
              <div className="error_field">
                <img src={dangerIcon} alt="Danger" className="danger_icon" />
                <p className="error_message">{translation.required}</p>
              </div>
            )}
          </div>
        ) : (
          <div className="card_empty_section_container">
            <div
              className={`cards_empty_section ${
                isError ? "cards_empty_section_error" : ""
              }`}
            >
              <img src={noCardIcon} alt="No Card" className="trade_no_card" />
              <p className="no_card_added">{translation.no_card_added}</p>
              <button
                className="card_add_button"
                type="button"
                onClick={handleOpenForm}
              >
                {translation.add_card_plus}
              </button>
            </div>
            {isError && (
              <div className="error_field">
                <img src={dangerIcon} alt="Danger" className="danger_icon" />
                <p className="error_message">{translation.required}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
