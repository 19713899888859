interface TranslationList {
  [key: string]: string;
}

export const localizationArmenian: TranslationList = {
  home: "Գլխավոր էջ",
  merchants: "Առևտրականներ",
  faq: "Հաճախակի տրվող հարցեր",
  login: "Մուտք գործել",
  sign_up: "Գրանցվել",
  support: "Աջակցում",
  usa: "Eng",
  rus: "Рус",
  arm: "Հայ",
  cn: "中文",
  copyright: "Հեղինակային իրավունքներ ©",
  copyright_crypto: "Կոբբեքս",
  about: "Մասին",
  about_us: "Մեր մասին",
  ongoing_trades: "Ընթացիկ գործարքներ",
  legal: "Իրավական տեղեկատվություն",
  terms_of_use: "Օգտագործման պայմաններ",
  privacy_policy: "Գաղտնիության քաղաքականություն",
  contact_us: "Կապ մեզ հետ",
  social_media: "Սոցիալական ցանցեր",
  name_input: "Անուն/Ազգանուն*",
  email: "Էլ. փոստ*",
  message: "Հաղորդագրություն*",
  all: "Բոլորը",
  buy: "Գնել",
  sell: "Վաճառել",
  search: "Որոնել",
  filter: "Ֆիլտր",
  merchant: "Առևտրական",
  currency: "Արժույթ",
  amount: "Գումար",
  price_rate: "Գին (ԱՄՆ դոլար)",
  banks: "Բանկեր",
  trade: "Առևտուր",
  date_of_birth: "Ծննդյան ամսաթիվ",
  phone_number: "Հեռախոսահամար*",
  passport_number: "Անձնագրի համար",
  passport_series: "Անձնագրի սերիա",
  passport_image: "Անձնագրի/Նույնականցման քարտի նկար*",
  passport_image_without_mandatory: "Անձնագրի/Նույնականցման քարտի նկար",
  password: "Գաղտնաբառ*",
  confirm_password: "Հաստատել գաղտնաբառը*",
  info_title:
    "Խնդրում ենք վերբեռնել Ձեր անձնագիրի նկարը ձեռքում բռնած: Համոզվեք, որ.",
  done: "Վերջ",
  already_have_account: "Արդեն ունե՞ք հաշիվ:",
  telegram_name: "Telegram-ի անուն",
  nickname: "Օգտանուն*",
  first_name: "Անուն*",
  last_name: "Ազգանուն*",
  dont_have_account: "Չունե՞ք հաշիվ:",
  create_trade: "Ստեղծել գործարք",
  my_wallet: "Իմ դրամապանակը",
  forgot_password: "Մոռացե՞լ եք գաղտնաբառը:",
  two_factor_auth: "Երկփուլային նույնականացում",
  two_factor_auth_desc:
    "Ներբեռնեք Microsoft-ի կամ Google-ի նույնականացման հավելվածը Ձեր բջջայինում և սկանավորեք այս QR կոդը։",
  cant_scan_qr: "Չե՞ք կարողանում սկանավորել QR-ը:",
  qr_scan_desc:
    "Եթե չեք կարողանում սկանավորել QR կոդը, մուտքագրեք այս կոդը նույնականացման հավելվածում",
  enter_verification_code: "Մուտքագրեք հաստատման կոդը։",
  account: "Օգտահաշիվ",
  wallet: "Դրամապանակ",
  withdrawal: "Ելք",
  balance_history: "Հաշվեկշռի պատմություն",
  transaction_history: "Գործարքների պատմություն",
  logout: "Դուրս գալ",
  logout_modal_text:
    "Համոզվա՞ծ եք, որ ցանկանում եք դուրս գալ Ձեր հաշվից: Համոզվեք, որ պահպանել եք բոլոր չպահպանված տվյալները:",
  yes: "Այո",
  cancel: "Չեղարկել",
  forgot_password_title: "Մոռացել եմ գաղտնաբառը",
  forgot_pass_modal_desc:
    "Մուտքագրեք Ձեր էլ. փոստի հասցեն, և մենք ձեզ կուղարկենք գաղտնաբառի վերականգնման հրահանգներ։",
  email_address: "Էլ. փոստի հասցե*",
  send_instruction: "Ուղարկել հրահանգ",
  new_password: "Նոր գաղտնաբառ*",
  success: "Հաջողված",
  error: "Սխալ",
  info: "Տեղեկություն",
  warning: "Զգուշացում",
  change_password: "Փոխել գաղտնաբառը",
  reset_password: "Վերականգնել գաղտնաբառը",
  set_password: "Սահմանել գաղտնաբառ",
  password_info:
    "Գաղտնաբառը պետք է բաղկացած լինի նվազագույնը 6 և առավելագույնը 20 նիշերից, պարունակի գոնե մեկ մեծատառ, մեկ փոքրատառ և մեկ թվանշան։",
  language: "Լեզու",
  required: "Պարտադիր",
  pattern_error:
    "Գաղտնաբառը պետք է բաղկացած լինի նվազագույնը 6 և առավելագույնը 20 նիշերից, պարունակի գոնե մեկ մեծատառ, մեկ փոքրատառ և մեկ թվանշան։",
  okay: "Լավ",
  thank_you_for_register: "Շնորհակալություն գրանցման համար։",
  congrats_for_register: "Շնորհավորում ենք, Դուք գրանցվեցիք։",
  email_check_message:
    "Խնդրում ենք ստուգել Ձեր էլ. փոստը՝ գրանցումը հաստատելու համար:",
  password_matches: "Գաղտնաբառերը չեն համընկնում",
  email_pattern_error: "Էլփոստի ձևաչափը սխալ է",
  example: "Օրինակ",
  upload_image: "Վերբեռնել կամ տեղադրել նկար",
  become_merchant: "Դառնալ առևտրական",
  submit: "Ուղարկել",
  balance: "Հաշվեկշիռ",
  save_changes: "Պահպանել փոփոխությունները",
  inactive: "Չակտիվացված",
  activated: "Ակտիվացված",
  activate: "Ակտիվացնել",
  inactivate: "Ապաակտիվացնել",
  old_password: "Հին գաղտնաբառ*",
  current_password: "Ընթացիկ գաղտնաբառ*",
  im_agree: "Համաձայն եմ",
  and: "և",
  load_more: "Բեռնել ավելին",
  price_rate_without: "Գնի առժույթը",
  create: "Ստեղծել ",
  home_table_title: "Գնել և վաճառել կրիպտո հեշտությամբ",
  rows_per_page: "Տող էջում",
  request_trade: "Գործարքի առաջարկ",
  request_trade_text:
    "Մուտքագրեք գումարը: Ձեր առաջարկը կուղարկվի վաճառողին: Եթե հաստատվի 30 րոպեի ընթացքում, կբացվի զրույց: Հակառակ դեպքում, առաջարկը ինքնաբերաբար կկորի:",
  offline_request_trade_text:
    "Մուտքագրեք գումարը: Ձեր հարցումը կուղարկվի վաճառողին։ Եթե այն հաստատվի, կբացվի զրույցի պատուհանը։",
  request_trade_info:
    "Գումարը չի կարող գերազանցել վաճառողի կողմից առաջարկվող գումարից:",
  active: "Ակտիվ",
  trade_id: "Գործարքի ID",
  activate_trade:
    "Վստահ եք, որ ցանկանում եք ակտիվացնել գործարքը: Գործարքը ակտիվացնելը կերեվա գործարքների աղյուսակում:",
  inactivate_trade:
    "Վստա՞հ եք, որ ցանկանում եք ապաակտիվացնել գործարքը։ Գործարքը անգործուն դարձնելը կհեռացնի այն գործարքների աղյուսակից:",
  close_trade: "Փակել գործարքը",
  close_trade_popup_text:
    "Վստա՞հ եք, որ ցանկանում եք փակել այս գործարքը: Գործարքի փակումը կավարտի զրույցը, և այն կտեղափոխվի Ձեր գործարքների պատմություն։",
  close_trade_text:
    "Վստա՞հ եք, որ ցանկանում եք փակել գործարքը։ Գործարքը փակելը կհեռացնի այն գործարքների աղյուսակից, և այս գործողությունը անհնար է վերականգնել:",
  id: "ID (Նույնականացում)",
  requests: "Հարցումներ",
  select_trade: "Ընտրել գործարք",
  select_trade_text:
    "Ընտրեք գործարքը ցանկից՝ բոլոր կապված հարցումները դիտելու համար։",
  select_request: "Ընտրել հարցումը",
  select_request_text:
    "Ընտրեք հարցումը ցանկից՝ բոլոր մանրամասները դիտելու համար։",
  pending: "Սպասման մեջ",
  requested_amount: "Պահանջված գումար",
  user_equest_time: "Օգտատիրոջ հարցման ժամը",
  accept: "Ընդունել",
  accept_request: "Ընդունել հարցումը",
  accept_request_text:
    "Վստա՞հ եք, որ ցանկանում եք ընդունել հարցումը: Հարցման ընդունումը կբացի զրույց օգտատիրոջ հետ:",
  cancel_request: "Չեղարկել հարցումը",
  cancel_request_text:
    "Վստա՞հ եք, որ ցանկանում եք չեղարկել այս հարցումը: Այս գործողությունը անդառնալի է:",
  in_progress: "Ընթացքի մեջ",
  request_id: "Հարցման ID",
  go_to_chat: "Անցնել զրուցարան",
  min_amount: "Նվզ. գումար",
  max_amount: "Առվ. գումար",
  arbitrage: "Արբիտրաժ",
  confirm: "Հաստատել",
  merchant_cancel_deal: " չեղարկել է գործարքը",
  merchant_reject_deal: " չեղարկել է գործարքը",
  canceled_deal_message:
    "Այս զրուցարանը կփակվի, և գործարքը կտեղափոխվի գործարքների պատմություն:",
  user_conform_deal: " հաստատել է գործարքը",
  sender_conform_deal: "Դուք հաստատել եք գործարքը։",
  sender_cancel_deal: "Դուք չեղարկել եք գործարքը։",
  sender_reject_deal: "Դուք չեղարկել եք գործարքը։",
  merchant_conform_deal: " հաստատաել է գործարքը",
  confirm_deal_message:
    "Եթե Դուք նույնպես հաստատեք, զրուցարանը կփակվի, և գործարքը կավարտվի:",
  confirm_deal_message_sender_first: "Եթե ",
  confirm_deal_message_sender_second: "ը նույնպես հաստատի, գործարքը կկատարվի:",
  cancel_trade: "Չեղարկել Գործարքը",
  cancel_trade_message:
    "Վստա՞հ եք, որ ցանկանում եք չեղարկել գործարքը: Գործարքի չեղարկումը կփակի զրուցարանը, և գործարքը կտեղափոխվի գործարքների պատմություն:",
  initiate_arbitrage: "Սկսել արբիտրաժ",
  initiate_arbitrage_message:
    "Ցանկանո՞ւմ եք սկսել արբիտրաժի գործընթաց: Եթե ընտրեք «Այո», ադմինը կմիանա Ձեր զրույցին՝ աջակցելու ամբողջ գործընթացին:",
  currencies: "Արժույթ",
  confirm_trade: "Հաստատել գործարքը",
  confirm_trade_text: "Վստա՞հ եք, որ ցանկանում եք հաստատել գործարքը:",
  closed: "Փակված",
  trade_time: "Գործարքի ժամ․",
  deal_id: "Գործարքի ID ",
  rate: "Արժույթ",
  status: "Կարգավիճակ",
  date: "Ամսաթիվ",
  action: "Գործողություն",
  id_nickname: "ID/օգտատեր",
  view_details: "Դիտել մանրամասները",
  canceled: "Չեղարկված",
  completed: "Ավարտված",
  source: "Աղբյուր",
  type: "Տեսակ",
  outcome: "Արդյունք",
  income: "Եկամուտ",
  type_message: "Մուտքագրեք հաղորդագրություն...",
  currency_mandatory: "Արժույթ*",
  amount_mandatory: "Գումար*",
  price_rate_mandatory: "Գնի առժույթը*",
  banks_mandatory: "Բանկեր*",
  quantity: "Քանակ",
  please_scan_qr:
    "Խնդրում ենք սկանավորել QR կոդը կամ պատճենել գեներացված հասցեն՝ Ձեր կրիպտո ակտիվները մուտքագրելու համար:",
  wallet_address: "Դրամապանակի հասցե",
  withdraw: "Կանխիկացում",
  step: "Քայլ",
  step_one_desc:
    "Հեշտությամբ փոխանցեք միջոցները Ձեր դրամապանակից Ձեր նախընտրած հաշվին՝ օգտագործելով մեր պարզեցված կանխիկացման գործընթացը",
  step_two_desc:
    "Կանխիկացման գործընթացը սկսելու համար անհրաժեշտ է հաշվի հաստատում։ Խնդրում ենք անցնել հաստատման գործընթացը՝ կանխիկացմանը շարունակելու համար:",
  withdrawal_amount_mandatory: "Կանխիկացման գումար*",
  destination_address_mandatory: "Նշանակման հասցե*",
  next: "Հաջորդը",
  proceed_withdrawal: "Շարունակել կանխիկացումը",
  accepted: "Ընթացքի մեջ",
  rejected: "Չեղարկված",
  trade_details: "Գործարքի մանրամասներ",
  no_requests: "Հարցումներ չկան",
  no_available_requests: "Այս գործարքի համար առկա հարցումներ չկան։",
  value_must_be_greater: "Գումարը պետք է լինի մեծ կամ հավասար 0",
  value_must_be_greater_than_currency: "Գումարը պետք է լինի մեծ կամ հավասար ",
  dont_have_enough_funds: "Դուք չունեք բավարար միջոցներ",
  confirm_pass: "Հաստատեք գաղտնաբառը",
  ongoing_requests: "Ընթացիկ հարցումներ",
  request: "Հարցում",
  for_withdrawals_please: "Կանխիկացման համար խնդրում ենք",
  two_factor_authentication: "երկփուլային հաստատում (2FA)",
  two_fa_error_message: "Պահանջվում է երկփուլային հաստատում։",
  empty_message_active: "Ակտիվ գործարքներ չեն գտնվել։",
  empty_message_inactive: "Ոչ ակտիվ գործարքներ չեն գտնվել։",
  empty_message_accepted: "Ընթացքի մեջ գտնվող հարցումներ չեն գտնվել։",
  empty_message_pending: "Ընթացքային հարցումներ չեն գտնվել։",
  empty_message_trade: "Հասանելի գործարքներ չկան։",
  empty_message_request: "Հասանելի հարցումներ չկան։",
  sender_arbitraged: "Դուք արեցիք արբիտրաժ",
  user_arbitraged: " արեց արբիտրաժ",
  arbitrage_message: "Ադմինիստրատորը հրավիրվել է զրուցարան։",
  trade_completed: "Գործարքն ավարտված է",
  trade_rate_modal_text:
    "Գործարքը հաջողությամբ ավարտվեց։ Շնորհակալություն համագործակցության համար։ Խնդրում ենք գնահատել միմյանց",
  value_must_be_greater_than: "Արժեքը պետք է լինի 0.000001-ից մեծ կամ հավասար։",
  two_factor_disable_message:
    "Շարունակելու համար, մուտքագրեք Google Authenticator հավելվածի կողմից տրամադրված կոդը։",
  address_pattern_error:
    "Հասցեն պետք է պարունակի միայն լատինական տառեր և թվեր։",
  no_results_match: "Համընկնումներ չեն գտնվել։",
  no_item_matched: "Տարրը չի գտնվել։",
  please_try_with_other_options: "Խնդրում ենք փորձել այլ տարբերակներ։",
  no_trades: "Գործարքներ չկան։",
  no_available_trades: "Հասանելի գործարքներ չկան։",
  no_requests_available: "Հասանելի հարցումներ չկան։",
  verified: "Հաստատված է։",
  usd_mandatory: "ԱՄՆ դոլար*",
  crypto_coin: "Կրիպտո դրամ",
  crypto_coin_mandatory: "Կրիպտո դրամ*",
  usd_price_rate: "ԱՄՆ դոլարի կուրս / 1$ ~ ՀՀԴ*",
  verify_checkbox_text:
    "Հարցումներ կարող են ուղարկել միայն վավերացված օգտատերերը",
  verify_your_account: "Օգտահաշվի վավերականացում",
  verify_account_modal_message:
    "Այս գործարքին հարցում ուղարկելու համար Դուք պետք է լինեք վավերացված օգտատեր (օգտահաշիվ ստեղծելիս չեք ավելացրել Ձեր անձնագրի նկարը)։ Խնդրում ենք անցնել Ձեր օգտահաշիվ և վերբեռնել անձնագրի նկարը՝ վավերականացման գործընթացը ավարտելու համար։",
  verify_account_pending_message:
    "Ձեր հաշվի վավերականացման հարցումը ներկայումս ընթացքի մեջ է: Հարցում ուղարկելու համար Դուք պետք է լինեք վավերականացված օգտատեր: Խնդրում ենք որոշ ժամանակ տալ մեր թիմին՝ Ձեր հարցումը վերանայելու համար: Եթե արդեն ներբեռնել եք Ձեր անձնագրի նկարը և երկար ժամանակ եք սպասել կամ այլ խնդիրներ են առաջացել, խնդրում ենք կապ հաստատել մեր աջակցման թիմի հետ։",
  go_to_profile: "Անցնել պրոֆիլ",
  one_usd_equivalent: "Մեկ ԱՄՆ դոլարը համարժեք է",
  amd: "ՀՀԴ",
  usd: "ԱՄՆ դոլար",
  usd_to_amd: "ԱՄՆ Դոլլար/ ՀՀԴ",
  upload_image_profile: "Վերբեռնել անձնագրի նկար։",
  satisfied_customers: "Գոհ հաճախորդներ",
  platform_uptime: "Հարթակի աշխատանքի ժամեր",
  armenian_market: "Հայկական շուկա",
  no_transactions_found: "Գործարքներ չեն գտնվել",
  no_data: "Տվյալներ չկան",
  no_balance_history: "Մնացորդի պատմություն չկա",
  my_cards: "Իմ քարտերը",
  no_cards: "Քարտեր չկան",
  no_cards_desc:
    "Քարտեր դեռևս չկան: Սեղմեք ստեղնի վրա նոր քարտ ավելացնելու համար:",
  add_card: "Ավելացնել քարտ",
  card_number: "Քարտի համար*",
  card_name: "Քարտի անուն*",
  card_name_text: "Մուտքագրեք անունը այնպես, ինչպես նշված է քարտի վրա։",
  add: "Ավելացնել",
  name_surname: "ԱՆՈՒՆ ԱԶԳԱՆՈՒՆ",
  delete_card: "Ջնջել քարտը",
  delete_card_text:
    "Այս գործողությունը չեղարկվել։ Եթե Դուք շարունակեք, քարտը անվերադարձ կջնջվի Ձեր պրոֆիլից։",
  delete: "Ջնջել",
  mark_offline_trade: "Կատարել օֆլայն գործարք",
  location_mandatory: "Տեղորոշում*",
  location: "Տեղորոշում",
  my_cards_mandatory: "Իմ քարտեր*",
  change_card: "Փոխել քարտերը",
  no_card_added: "Չկան ավելացված քարտեր",
  add_card_plus: "+Ավելացնել քարտ",
  save: "Պահպանել",
  enter_valid_card: "Մուտքագրել վավեր քարտ",
  online: "Օնլայն",
  offline: "Օֆլայն",
  close: "Փակել",
  min_max_character_count:
    "Քարտի անունը պետք է լինի 2 նիշից երկար և 50-ից կարճ",
  location_pattern_error:
    "2-ից 50 նիշ (պետք է պարունակի միայն տառեր, թվեր և #, -, /, ., ', &)։",
  request_is_arbitraged: "Հարցումը արբիտրաժի մեջ է",
  passport_image_info: "Ձեր դեմքը և անձնագրի մանրամասները հստակ երևում են։",
  passport_image_info_step_one:
    "Պատկերը մաքուր է, լավ լուսավորված և առանց շողքի։",
  passport_image_info_step_two: "Անձնագրի բոլոր չորս անկյունները տեսանելի են",
  max_size: "Առավելագույն չափը՝",
  five_megabit: "10MB",
  format: "Ձևաչափ՝",
  format_types: "(JPG, JPEG, PNG)",
  fee_modal_text:
    "Խնդրում ենք մանրամասնորեն ստուգել գործարքի տվյալները։ Հաստատո՞ւմ եք, որ համաձայն եք շարունակել։",
  fee_calculation: "Վճարի հաշվարկում",
  transfer: "Transfer",
  deposit: "Deposit",
  actual_amount: "Փաստացի գումար:",
  fee: "Վճար:",
  fee_text:
    "(Մեր ծառայության շրջանակներում գործարքների համար վճար չի գանձվում, արտաքին գործարքների համար հնարավոր են վճարներ)",
  account_restricted: "Հաշիվը Արգելափակված Է",
  account_restricted_text:
    "Ձեր հաշիվը մասնակի արգելափակված է: Գործողություններ, ինչպիսիք են միջոցների դուրսբերումը, գործարքների ստեղծումը կամ հարցումների ուղարկումը, ներկայումս անհասանելի են: Լրացուցիչ աջակցություն ստանալու համար խնդրում ենք կապ հաստատել մեր թիմի հետ:",
  network_connection_error:
    "Ինտերնետ կապի հետ խնդիր է առաջացել։ Խնդրում ենք ստուգել հասանելիությունը և փորձել կրկին",
  big_image_error_msg:
    "Մեկ կամ ավելի ֆայլերի չափը գերազանցում է 10ՄԲ-ի սահմանը:",
  price_rate_error_online:
    "Գործարքի գումարը պետք է լինի $20-ից մինչև $10,000-ի սահմաններում: Խնդրում ենք նկատի ունենալ, որ փոխարժեքի տատանումների պատճառով սահմանները կարող են փոփոխվել մինչև 2%-ով",
  price_rate_error_offline:
    "Գործարքի գումարը պետք է լինի $5000-ից մինչև $200,000-ի սահմաններում: Խնդրում ենք նկատի ունենալ, որ փոխարժեքի տատանումների պատճառով սահմանները կարող են փոփոխվել մինչև $10-ով",
  price_rate_request_error_online:
    "Գումարը պետք է լինի $20-ից մինչև $10,000-ի սահմաններում: Խնդրում ենք նկատի ունենալ, որ փոխարժեքի տատանումների պատճառով սահմանները կարող են փոփոխվել մինչև 2%-ով",
  price_rate_request_error_offline:
    "Գումարը պետք է լինի $5000-ից մինչև $200,000-ի սահմաններում: Խնդրում ենք նկատի ունենալ, որ փոխարժեքի տատանումների պատճառով սահմանները կարող են փոփոխվել մինչև $10-ով",
};
