import { FC } from "react";

import { useTranslation } from "localization";
import { useAppSelector } from "reduxState/store";
import { USER_TYPES } from "constants/global";
import { MESSAGE_NOTIFICATION_TYPES } from "constants/trade";

import successIcon from "assets/images/toast/success.svg";
import rejectIcon from "assets/images/toast/error.svg";
import arbitrageIcon from "assets/images/toast/info.svg";
import { getHoursMinutes } from "helpers";

const icons = {
  completed: successIcon,
  adminCompleted: successIcon,
  adminRejected: rejectIcon,
  rejected: rejectIcon,
  arbitrage: arbitrageIcon,
};

interface ITradeConfirmed {
  type: MESSAGE_NOTIFICATION_TYPES;
  sender_id: number | null | undefined;
  sender_name: string;
  date: Date;
  isAdminAction?: boolean;
  isOnlineFormat: boolean;
}

export const TradeConfirmed: FC<ITradeConfirmed> = ({
  type,
  sender_id,
  sender_name,
  date,
  isAdminAction,
  isOnlineFormat,
}) => {
  const user = useAppSelector((state) => state.auth.user);

  const isSender = user?.id === sender_id;
  const isMerchant = user?.role === USER_TYPES.merchant;

  const translation = useTranslation();

  return (
    <div className={`trade_notification ${type}_trade_notification`}>
      <div className="notification_icon_field">
        <img src={icons[type]} alt={type} className="notification_icon" />
      </div>
      <div className="notification_field">
        {!isAdminAction && (
          <>
            <p className="notification_type">
              {!isSender && sender_name}
              {isSender
                ? MESSAGE_NOTIFICATION_TYPES.ARBITRAGE === type
                  ? translation.sender_arbitraged
                  : MESSAGE_NOTIFICATION_TYPES.COMPLETED === type
                  ? translation.sender_conform_deal
                  : isOnlineFormat
                  ? translation.sender_cancel_deal
                  : translation.sender_reject_deal
                : MESSAGE_NOTIFICATION_TYPES.ARBITRAGE === type
                ? translation.user_arbitraged
                : MESSAGE_NOTIFICATION_TYPES.COMPLETED === type
                ? isMerchant
                  ? translation.user_conform_deal
                  : translation.merchant_conform_deal
                : isOnlineFormat
                ? translation.merchant_cancel_deal
                : translation.merchant_reject_deal}
            </p>
            <p className="notification_message">
              {MESSAGE_NOTIFICATION_TYPES.COMPLETED === type
                ? translation.confirm_deal_message
                : MESSAGE_NOTIFICATION_TYPES.ARBITRAGE === type
                ? translation.arbitrage_message
                : translation.canceled_deal_message}
            </p>
          </>
        )}
        {isAdminAction && (
          <>
            <p className="notification_type">
              {!isSender && sender_name}
              {MESSAGE_NOTIFICATION_TYPES.ADMIN_COMPLETED === type
                ? translation.merchant_conform_deal
                : translation.merchant_cancel_deal}
            </p>
          </>
        )}
      </div>
      <div className="notification_date">
        <span className="date">{getHoursMinutes(date as Date)}</span>
      </div>
    </div>
  );
};
