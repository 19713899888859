import { useEffect } from "react";
import { motion } from "framer-motion";

import { useTranslation } from "localization";
import { MainButton } from "components/buttons";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { getHomeContent } from "reduxState";
import { GLOBAL } from "constants/global";

import homeBannerImage from "assets/images/banners/homeBanner.webp";

export const HomeBanner = () => {
  const data = useAppSelector((state) => state.content.homeContent);

  const translation = useTranslation();
  const dispatch = useAppDispatch();

  const redirectToTelegram = () => {
    window.open(GLOBAL.telegram_link, "_blank");
  };

  useEffect(() => {
    dispatch(getHomeContent());
  }, []);

  return (
    <div className="home_banner" id="home_banner_id">
      <img src={homeBannerImage} alt="Banner" className="home_banner_image" />
      <motion.div
        className="home_page_content"
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 2 }}
      >
        <div className="home_page_title_section">
          <h1 className="home_page_title">{data?.title}</h1>
        </div>
        <div className="home_page_desc_section">
          <p className="home_page_desc">{data?.content}</p>
        </div>
        <div className="support_button_field">
          <MainButton
            customClass="custom_button width_fit"
            name={translation.support}
            onClick={redirectToTelegram}
          />
        </div>
      </motion.div>
    </div>
  );
};
