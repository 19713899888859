import { FC, useRef, useState } from "react";

import { ICurrencySelectBox } from "types";

import useOutsideClick from "hooks/useOutsideClick";
import { SelectBalanceCurrency } from "./SelectBalanceCurrency";

export const SelectCurrencyBox: FC<ICurrencySelectBox> = ({
  name,
  label,
  setSelectedCurrencyShortName,
  selectedCurrencyShortName,
}) => {
  const [openSelect, setOpenSelect] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);

  const handleCloseDropdown = () => {
    setOpenSelect(false);
  };

  useOutsideClick({ ref: ref, handler: handleCloseDropdown });

  return (
    <div className={`text_input_container ${!label ? "remove_gap" : ""} `}>
      <div className="input_field" ref={ref}>
        <SelectBalanceCurrency
          openSelect={openSelect}
          setOpenSelect={setOpenSelect}
          name={name}
          handleCloseDropdown={handleCloseDropdown}
          setSelectedCurrencyShortName={setSelectedCurrencyShortName}
          label={label}
          selectedCurrencyShortName={selectedCurrencyShortName}
        />
      </div>
    </div>
  );
};
