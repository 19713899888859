import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "reduxState/store";
import {
  setShowCreateTrade,
  setShowMobileMenu,
  setShowPartiallyBlockModal,
} from "reduxState/features/main";
import { MainButton } from "components/buttons";
import { useTranslation } from "localization";
import { GLOBAL, PATHNAMES, USER_TYPES } from "constants/global";
import { UserSection } from "./UserSection";
import { MobileNavbar } from "./Mobilenavbar";

import logo from "assets/images/logo.svg";
import closeIcon from "assets/images/close.svg";
import supportIcon from "assets/images/support.png";

export const MobileMenu = () => {
  const [userType, setUserType] = useState<string>(USER_TYPES.guest);
  const [collapseMenu, setCollapseMenu] = useState<boolean>(false);

  const user = useAppSelector((state) => state.auth.user);

  const translation = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const routeLogin = () => {
    dispatch(setShowMobileMenu(false));
    navigate(PATHNAMES.sign_in);
  };

  const routeSignUp = () => {
    dispatch(setShowMobileMenu(false));
    navigate(PATHNAMES.sign_up);
  };

  const handleClose = () => {
    setCollapseMenu(true);
    setTimeout(() => {
      dispatch(setShowMobileMenu(false));
    }, 1000);
  };

  const handleLanguageChange = () => {
    setCollapseMenu(true);
    dispatch(setShowMobileMenu(false));
  };

  const openCreateTrade = () => {
    if (user?.isInactive) {
      dispatch(setShowPartiallyBlockModal(true));
      return;
    }
    handleClose();
    dispatch(setShowCreateTrade(true));
  };

  const redirectToTelegram = () => {
    window.open(GLOBAL.telegram_link, "_blank");
  };

  useEffect(() => {
    setUserType(!!user ? user?.role : USER_TYPES.guest);
  }, [user]);

  return (
    <div
      className={`mobile_menu_container ${
        collapseMenu ? "blur_animation" : ""
      }`}
    >
      <div
        id="mobile_menu_id"
        className={`mobile_menu ${collapseMenu ? "collapse_menu" : ""}`}
      >
        <div className="menu_header">
          <div className="menu_logo">
            <Link to="/" onClick={handleClose}>
              <img src={logo} alt="Mobile Logo" className="mobile_logo" />
            </Link>
          </div>
          <div className="close_section">
            <img
              src={closeIcon}
              alt="Close"
              className="menu_close_icon"
              onClick={handleClose}
            />
          </div>
        </div>
        <div className="user_section">
          {userType === USER_TYPES.guest ? (
            <div className="guest_actions">
              <MainButton
                name={translation.login}
                customClass="log_in_button width_100"
                onClick={routeLogin}
              />
              <MainButton
                name={translation.sign_up}
                customClass="custom_button width_100"
                onClick={routeSignUp}
              />
            </div>
          ) : (
            <UserSection handleClose={handleClose} />
          )}
        </div>

        <MobileNavbar
          handleClose={handleClose}
          handleLanguageChange={handleLanguageChange}
        />

        <div className="action_buttons_field">
          {userType === USER_TYPES.guest && (
            <MainButton
              name={translation.support}
              onClick={redirectToTelegram}
              customClass="custom_button"
            />
          )}
          {userType === USER_TYPES.user && (
            <MainButton
              name={translation.support}
              onClick={redirectToTelegram}
              customClass="custom_button width_100"
            />
          )}
          {userType === USER_TYPES.merchant && (
            <div className="merchant_actions_button">
              <MainButton
                name={translation.create_trade}
                customClass="custom_button create_trade_btn"
                onClick={openCreateTrade}
              />
              <img src={supportIcon} alt="Support" className="support_icon" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
