import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useTranslation } from "localization";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { GLOBAL } from "constants/global";
import { UserRequestElement } from "./UserRequestElement";
import { getUserTradeRequestById } from "reduxState";
import { CHAT_TRADE_REQUEST_STATUSES } from "constants/trade";
import { PageLoading } from "components";

import { Chat } from "../../chat";

export const UserTradeRequest = () => {
  const data = useAppSelector((state) => state.trades.userRequestData);
  const loading = useAppSelector(
    (state) => state.trades.userRequestDataLoading
  );
  const userData = useAppSelector((state) => state.trades.userRequests);

  const translation = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const query = new URLSearchParams(useLocation().search);
  const requestId = query.get(GLOBAL.request_id);

  useEffect(() => {
    if (requestId) dispatch(getUserTradeRequestById({ id: Number(requestId) }));
  }, [requestId]);

  useEffect(() => {
    if (requestId && !userData?.find((item) => item.id === Number(requestId))) {
      query.delete(GLOBAL.request_id);
      navigate(`?${query.toString()}`);
    }
  }, [userData]);

  return (
    <div
      className={`ongoing_trades_list ${
        requestId ? "" : "hide_requests_mobile"
      }`}
    >
      {requestId && !loading ? (
        CHAT_TRADE_REQUEST_STATUSES.includes(data?.merchant_status) ? (
          <Chat />
        ) : (
          <div className="trades_request_list">
            <div className="trade_request_title">
              <span>{translation.request}</span>{" "}
              <span className="trade_id_field">{`${translation.id} ${requestId}`}</span>
            </div>
            <div className="trades_request">
              <UserRequestElement element={data} />
            </div>
          </div>
        )
      ) : loading ? (
        <PageLoading loading={loading} />
      ) : (
        <div className="empty_requests">
          <h3 className="select_trade">{translation.select_request}</h3>
          <p className="select_trade_text">{translation.select_request_text}</p>
        </div>
      )}
    </div>
  );
};
