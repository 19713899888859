import { FC, Fragment, MouseEvent, memo, useEffect, useState } from "react";

import { useAppSelector } from "reduxState/store";
import { TradeConfirmed } from "./TradeConfirmStatus";
import { getHoursMinutes } from "helpers";
import { ChatImagesModal } from "components/modals";
import { MOBILE_WINDOW_WIDTH } from "constants/global";
import {
  IMAGES_COUNT,
  MESSAGE_NOTIFICATION_TYPES,
  TRADE_FORMAT_TYPES,
} from "constants/trade";
import { IChatMessages, IUserRequest } from "types";
import { v4 as uuidv4 } from "uuid";

const imageUrl = process.env.REACT_APP_API_IMAGE_URL;

interface IMessageData {
  messages: IChatMessages[];
  requestData: IUserRequest;
}

export const ChatMessages: FC<IMessageData> = memo(
  ({ messages, requestData }) => {
    const [showImagesModal, setShowImagesModal] = useState<boolean>(false);
    const [showAllImages, setShowAllImages] = useState<boolean>(false);
    const [selectedImages, setSelectedImages] = useState<string[] | File[]>([]);
    const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);

    const user = useAppSelector((state) => state.auth.user);

    const innerWidth = window.innerWidth;
    const shownImagesCount =
      innerWidth > MOBILE_WINDOW_WIDTH
        ? IMAGES_COUNT.desktop
        : IMAGES_COUNT.mobile;

    const handleShowAllImages = (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      setShowAllImages(true);
    };

    const handleImagePreview = (images: string[] | File[], index: number) => {
      setSelectedImageIndex(index);
      setSelectedImages(images);
      setShowImagesModal(true);
    };

    const handleCloseModal = () => {
      setSelectedImageIndex(0);
      setSelectedImages([]);
      setShowImagesModal(false);
    };

    useEffect(() => {
      if (!showImagesModal) {
        document.body.style.overflow = "unset";
      }
    }, [showImagesModal]);

    return (
      <div
        className="chat_messages_section"
        id="chat_popup_messages_section_id"
      >
        {showImagesModal && (
          <ChatImagesModal
            onClose={handleCloseModal}
            images={selectedImages}
            selectedImageIndex={selectedImageIndex}
          />
        )}
        <div className="chat_messages">
          {messages?.map((element) =>
            element.is_arbitraged ? (
              <div className="notification_message">
                <TradeConfirmed
                  type={MESSAGE_NOTIFICATION_TYPES.ARBITRAGE}
                  sender_id={element.sender_id}
                  sender_name={element.sender_name}
                  date={element.created_at as Date}
                  isOnlineFormat={
                    requestData.format === TRADE_FORMAT_TYPES.ONLINE
                  }
                />
              </div>
            ) : element.request_state ? (
              <div className="notification_message">
                <TradeConfirmed
                  type={
                    element.request_state ===
                    MESSAGE_NOTIFICATION_TYPES.COMPLETED
                      ? MESSAGE_NOTIFICATION_TYPES.COMPLETED
                      : MESSAGE_NOTIFICATION_TYPES.REJECTED
                  }
                  sender_name={element.sender_name}
                  sender_id={element.sender_id}
                  date={element.created_at as Date}
                  isOnlineFormat={
                    requestData.format === TRADE_FORMAT_TYPES.ONLINE
                  }
                />
              </div>
            ) : element.admin_action ? (
              <div className="notification_message">
                <TradeConfirmed
                  type={
                    element.admin_action ===
                    MESSAGE_NOTIFICATION_TYPES.COMPLETED
                      ? MESSAGE_NOTIFICATION_TYPES.ADMIN_COMPLETED
                      : MESSAGE_NOTIFICATION_TYPES.ADMIN_REJECTED
                  }
                  isAdminAction={true}
                  sender_name={element.sender_name}
                  sender_id={element.sender_id}
                  date={element.created_at as Date}
                  isOnlineFormat={
                    requestData.format === TRADE_FORMAT_TYPES.ONLINE
                  }
                />
              </div>
            ) : (
              <div
                key={`${element.created_at}`}
                className={`message_field ${
                  element.sender_id === user?.id ? "my_messages" : ""
                } ${element.is_admin ? "admin_message" : ""}`}
              >
                {element.sender_id !== user?.id && (
                  <p className="sender_name">{element.sender_name}</p>
                )}
                <p className="message">{element.message}</p>
                {element?.attachmentPaths &&
                  !!element?.attachmentPaths?.length && (
                    <div className="chat_image">
                      {element?.attachmentPaths.map((image, index) =>
                        index > shownImagesCount && !showAllImages ? (
                          <Fragment key={uuidv4()}></Fragment>
                        ) : (
                          <div
                            className={`images_grid_element ${
                              element.attachmentPaths.length === 1
                                ? "one_images_grid_element"
                                : ""
                            }`}
                            key={uuidv4()}
                          >
                            <img
                              src={
                                typeof image === "string"
                                  ? `${imageUrl}${image}`
                                  : URL.createObjectURL(image)
                              }
                              alt="Attach"
                              crossOrigin="anonymous"
                              className="attach_image"
                              onClick={() =>
                                handleImagePreview(
                                  element.attachmentPaths,
                                  index
                                )
                              }
                            />
                            {index === shownImagesCount && !showAllImages && (
                              <div
                                className="last_element"
                                onClick={handleShowAllImages}
                              >
                                {`+${
                                  element.attachmentPaths.length -
                                  (shownImagesCount + 1)
                                }`}
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  )}
                <div className="message_date">
                  <span className="date">
                    {getHoursMinutes(element?.created_at)}
                  </span>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    );
  }
);
