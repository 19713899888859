import { FC, Fragment, useEffect, useRef, useState } from "react";

import { BANKS_MAX_LENGTH } from "constants/table";
import useOutsideClick from "hooks/useOutsideClick";
import { MAC_WINDOW_WIDTH } from "constants/global";

import arrowIcon from "assets/images/arrow-down-grey.svg";

interface IBanks {
  banks: string[];
}

export const BanksSection: FC<IBanks> = ({ banks }) => {
  const [openBanksDropdown, setOpenBanksDropdown] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);

  const handleShowBanks = () => {
    setOpenBanksDropdown(!openBanksDropdown);
  };

  const handleClose = () => {
    setOpenBanksDropdown(false);
  };

  const MAX_BANK_NAME_LENGTH = window.innerWidth > MAC_WINDOW_WIDTH ? 19 : 13;

  useOutsideClick({ ref: ref, handler: handleClose });

  useEffect(() => {
    if (openBanksDropdown) {
      const dropdown = document.getElementById(
        "bank_dropdown"
      ) as HTMLDivElement;

      const elementYCoordinate = ref.current?.getBoundingClientRect().top ?? 0;

      if (elementYCoordinate + 300 >= window.innerHeight) {
        dropdown.style.bottom = "40px";
        dropdown.style.top = "unset";
      } else {
        dropdown.style.top = "40px";
        dropdown.style.bottom = "unset";
      }
    }
  }, [openBanksDropdown]);

  return (
    <div
      className="banks_row"
      id="banks_rows_id"
      ref={ref}
      onClick={() =>
        banks.length > BANKS_MAX_LENGTH ? handleShowBanks() : () => {}
      }
    >
      <div className="banks_names_section">
        {banks?.map((bank, index) =>
          index < BANKS_MAX_LENGTH ? (
            <span
              key={bank}
              className={`banks_name ${
                bank.length > MAX_BANK_NAME_LENGTH
                  ? "banks_name_first_ellipsis"
                  : ""
              }`}
            >
              {bank}
              {banks.length !== index + 1 &&
                bank.length < MAX_BANK_NAME_LENGTH + 1 && <>, </>}
            </span>
          ) : index === BANKS_MAX_LENGTH &&
            banks?.[0]?.length < MAX_BANK_NAME_LENGTH + 1 ? (
            <span key={bank} className="banks_name bank_name_ellipsis">
              {bank}
            </span>
          ) : (
            <Fragment key={bank}></Fragment>
          )
        )}
      </div>
      {banks?.length > BANKS_MAX_LENGTH && (
        <div className="hidden_banks">
          <span>+{banks.length - BANKS_MAX_LENGTH}</span>
          <img
            src={arrowIcon}
            alt="Arrow"
            className={`arrow_icon ${openBanksDropdown ? "rotate_icon" : ""}`}
          />
        </div>
      )}
      {openBanksDropdown && (
        <div className="banks_dropdown" id="bank_dropdown">
          {banks.map((bank) => (
            <div className="bank_dropdown_element" key={bank}>
              {bank}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
