import { useNavigate } from "react-router-dom";

import { useTranslation } from "localization";
import { COUNTRIES, LANGUAGE } from "constants/header";
import { useAppDispatch } from "reduxState/store";
import { setUserLoading } from "reduxState/features/auth";
import { GLOBAL } from "constants/global";

export const LanguageDropdown = () => {
  const translation = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleChangeLanguage = (prefix: string) => {
    dispatch(setUserLoading(true));
    localStorage.setItem(LANGUAGE, prefix);
    sessionStorage.setItem(GLOBAL.loading, GLOBAL.loading);
    navigate(0);
  };

  return (
    <div className="language_dropdown">
      {COUNTRIES.map((country) => (
        <div
          className="language_element"
          key={country.id}
          onClick={() => handleChangeLanguage(country.prefix)}
        >
          <img src={country.image} alt="Country" className="dropdown_country" />
          <p className="country_name">{translation[country.name]}</p>
        </div>
      ))}
      <div></div>
    </div>
  );
};
