import { ChangeEvent } from "react";
import { FieldValues, UseFormSetValue } from "react-hook-form";

import { KEY_NAMES } from "constants/global";

interface ICardNumberTyping {
  value: string;
  event: ChangeEvent<HTMLInputElement>;
  name: string;
  setValue: UseFormSetValue<FieldValues>;
  pressedKey: string;
}

export const cardNumberTyping = ({
  value,
  event,
  name,
  setValue,
  pressedKey,
}: ICardNumberTyping) => {
  // Remove any non-digit characters and limit length based on card type
  const cleanValue = value.replace(/\D/g, "");

  const input = event.target;
  const cursorPosition = input.selectionStart ?? 0;

  let maxLength = 19; // Default maximum length for most cards
  let formattedValue = cleanValue;

  // Check card type based on starting digits and set max length
  if (/^3[47]/.test(cleanValue)) {
    // American Express (AMEX)
    maxLength = 15;
    formattedValue = cleanValue
      .slice(0, maxLength)
      .replace(/(\d{4})(\d{6})?(\d{0,5})?/, (match, p1, p2, p3) => {
        let result = p1;
        if (p2) result += " " + p2;
        if (p3) result += " " + p3;
        return result;
      });
  } else if (/^4/.test(cleanValue)) {
    // Visa
    maxLength = cleanValue.length > 16 ? 19 : 16;
    formattedValue = cleanValue
      .slice(0, maxLength)
      .replace(/(\d{4})(?=\d)/g, "$1 ");
  } else if (
    /^5[1-5]|^222[1-9]|^22[3-9]\d|^2[3-6]\d{2}|^27[01]\d|^2720/.test(cleanValue)
  ) {
    // MasterCard
    maxLength = 16;
    formattedValue = cleanValue
      .slice(0, maxLength)
      .replace(/(\d{4})(?=\d)/g, "$1 ");
  } else if (
    /^6011|^65|^64[4-9]|^622(12[6-9]|1[3-9]\d|2[0-9]{2}|9[01]\d|92[0-5])/.test(
      cleanValue
    )
  ) {
    // Discover
    maxLength = cleanValue.length > 16 ? 19 : 16;
    formattedValue = cleanValue
      .slice(0, maxLength)
      .replace(/(\d{4})(?=\d)/g, "$1 ");
  } else if (/^3(?:0[0-5]|[68])/.test(cleanValue)) {
    // Diners Club
    maxLength = 16;
    formattedValue = cleanValue
      .slice(0, maxLength)
      .replace(/(\d{4})(?=\d)/g, "$1 ");
  } else if (/^352[8-9]|^35[3-8]/.test(cleanValue)) {
    // JCB (Japan Credit Bureau)
    maxLength = cleanValue.length > 16 ? 19 : 16;
    formattedValue = cleanValue
      .slice(0, maxLength)
      .replace(/(\d{4})(?=\d)/g, "$1 ");
  } else if (/^62/.test(cleanValue)) {
    // UnionPay
    maxLength = 19;
    formattedValue = cleanValue
      .slice(0, maxLength)
      .replace(/(\d{4})(?=\d)/g, "$1 ");
  } else if (/^9/.test(cleanValue)) {
    // ARCA (Armenia)
    maxLength = 16;
    formattedValue = cleanValue
      .slice(0, maxLength)
      .replace(/(\d{4})(?=\d)/g, "$1 ");
  } else if (/^2(?:20[0-4])/.test(cleanValue)) {
    // Mir
    maxLength = 16;
    formattedValue = cleanValue
      .slice(0, maxLength)
      .replace(/(\d{4})(?=\d)/g, "$1 ");
  } else if (/^(5[06789]|6\d)/.test(cleanValue)) {
    // Maestro (typically 12-19 digits, general fallback for wide range of lengths)
    maxLength = 19;
    formattedValue = cleanValue
      .slice(0, maxLength)
      .replace(/(\d{4})(?=\d)/g, "$1 ");
  } else {
    // Fallback for unknown types, apply general formatting with spaces
    formattedValue = cleanValue
      .slice(0, maxLength)
      .replace(/(\d{4})(?=\d)/g, "$1 ");
  }

  const cursorAdjustment = formattedValue.length - cleanValue.length;
  let newCursorPosition = cursorPosition + cursorAdjustment;

  newCursorPosition = Math.min(newCursorPosition, formattedValue.length);

  setValue(name, formattedValue.trim());

  requestAnimationFrame(() => {
    if (
      pressedKey === KEY_NAMES.backspace ||
      pressedKey === KEY_NAMES.delete ||
      newCursorPosition !== formattedValue.length
    ) {
      input.setSelectionRange(cursorPosition, cursorPosition);
    } else {
      input.setSelectionRange(cursorPosition + 1, cursorPosition + 2);
    }
  });
};

export const getCardNumberLength = ({ value }: { value: string }) => {
  const cleanValue = value.replace(/\D/g, "");

  const cardTypeMaxLengths: { [key: string]: number } = {
    amex: 15,
    visa: cleanValue.length > 16 ? 19 : 16,
    mastercard: 16,
    discover: cleanValue.length > 16 ? 19 : 16,
    diners: 16,
    jcb: cleanValue.length > 16 ? 19 : 16,
    unionpay: 19,
    arca: 16,
    mir: 16,
    maestro: 19,
  };

  if (/^3[47]/.test(cleanValue)) return cardTypeMaxLengths.amex;
  if (/^4/.test(cleanValue)) return cardTypeMaxLengths.visa;
  if (
    /^5[1-5]|^222[1-9]|^22[3-9]\d|^2[3-6]\d{2}|^27[01]\d|^2720/.test(cleanValue)
  )
    return cardTypeMaxLengths.mastercard;
  if (
    /^6011|^65|^64[4-9]|^622(12[6-9]|1[3-9]\d|2[0-9]{2}|9[01]\d|92[0-5])/.test(
      cleanValue
    )
  )
    return cardTypeMaxLengths.discover;
  if (/^3(?:0[0-5]|[68])/.test(cleanValue)) return cardTypeMaxLengths.diners;
  if (/^352[8-9]|^35[3-8]/.test(cleanValue)) return cardTypeMaxLengths.jcb;
  if (/^62/.test(cleanValue)) return cardTypeMaxLengths.unionpay;
  if (/^9/.test(cleanValue)) return cardTypeMaxLengths.arca;
  if (/^2(?:20[0-4])/.test(cleanValue)) return cardTypeMaxLengths.mir;
  if (/^(5[06789]|6\d)/.test(cleanValue)) return cardTypeMaxLengths.maestro;

  return 19;
};

export const maskCardNumber = (cardNumber: string) => {
  const cleanNumber = cardNumber?.replace(/\D/g, "");
  let maskedCard = cleanNumber;

  // Detect card type based on starting digits
  if (cleanNumber?.startsWith("34") || cleanNumber?.startsWith("37")) {
    // American Express (AMEX)
    maskedCard = cleanNumber?.replace(/^(\d{4})\d{6}(\d{5})$/, "$1 ****** $2");
  } else if (cleanNumber?.startsWith("4")) {
    // Visa
    const visaLength = cleanNumber?.length === 13 ? "******" : "**** ****";
    maskedCard = cleanNumber?.replace(
      /^(\d{4})\d{6,11}(\d{4})$/,
      `$1 ${visaLength} $2`
    );
  } else if (
    /^5[1-5]/.test(cleanNumber) ||
    /^222[1-9]|22[3-9]\d|2[3-6]\d{2}|27[01]\d|2720/.test(cleanNumber)
  ) {
    // MasterCard
    maskedCard = cleanNumber?.replace(
      /^(\d{4})\d{6,11}(\d{4})$/,
      "$1 **** **** $2"
    );
  } else if (
    /^6011|^65|^64[4-9]|^622(12[6-9]|1[3-9]\d|2[0-9]{2}|9[01]\d|92[0-5])/.test(
      cleanNumber
    )
  ) {
    // Discover
    maskedCard = cleanNumber?.replace(
      /^(\d{4})\d{6,11}(\d{4})$/,
      "$1 **** **** $2"
    );
  } else if (/^3(?:0[0-5]|[68])/.test(cleanNumber)) {
    // Diners Club
    maskedCard = cleanNumber.replace(/^(\d{4})\d{6}(\d{4})$/, "$1 ****** $2");
  } else if (/^352[8-9]|^35[3-8]/.test(cleanNumber)) {
    // JCB (Japan Credit Bureau)
    maskedCard = cleanNumber?.replace(
      /^(\d{4})\d{6,11}(\d{4})$/,
      "$1 **** **** $2"
    );
  } else if (/^62/.test(cleanNumber)) {
    // UnionPay
    maskedCard = cleanNumber?.replace(
      /^(\d{4})\d{6,11}(\d{4})$/,
      "$1 **** **** $2"
    );
  } else if (/^9/.test(cleanNumber)) {
    // ARCA (Armenia)
    maskedCard = cleanNumber?.replace(
      /^(\d{4})\d{6,11}(\d{4})$/,
      "$1 **** **** $2"
    );
  } else if (/^2(?:20[0-4])/.test(cleanNumber)) {
    // Mir
    maskedCard = cleanNumber?.replace(
      /^(\d{4})\d{6,11}(\d{4})$/,
      "$1 **** **** $2"
    );
  } else if (/^(5[06789]|6\d)/.test(cleanNumber)) {
    // Maestro (varies widely, general mask)
    maskedCard = cleanNumber?.replace(
      /^(\d{4})\d{6,11}(\d{4})$/,
      "$1 **** **** $2"
    );
  } else {
    // Default fallback
    maskedCard = cleanNumber?.replace(
      /^(\d{4})\d{6,11}(\d{4})$/,
      "$1 **** **** $2"
    );
  }

  return maskedCard;
};
