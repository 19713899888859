import { FC, FocusEvent, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";

import { IFilterInput } from "types";
import { GLOBAL } from "constants/global";

import "react-datepicker/dist/react-datepicker.css";

export const DateInput: FC<IFilterInput> = ({
  name,
  placeholder,
  setFilters,
}) => {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [startDate, endDate] = dateRange;

  const querySearch = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();

  const handleOnBlur = (e: FocusEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e;
    const splitted = value.split(" - ");
    const changedStartDate = new Date(splitted[0]);
    const changedEndDate = new Date(splitted[1]);

    if (
      startDate?.getTime() !== changedStartDate.getTime() ||
      endDate?.getTime() !== changedEndDate.getTime()
    ) {
      if (
        !isNaN(changedStartDate.getTime()) &&
        changedStartDate <= new Date() &&
        (isNaN(changedEndDate.getTime()) || changedStartDate <= changedEndDate)
      ) {
        setDateRange((prev) => [changedStartDate, prev[1]]);
      }

      if (
        !isNaN(changedEndDate.getTime()) &&
        changedEndDate <= new Date() &&
        (isNaN(changedStartDate.getTime()) ||
          changedStartDate <= changedEndDate)
      ) {
        setDateRange((prev) => [prev[0], changedEndDate]);
      }

      if (
        !isNaN(changedStartDate.getTime()) &&
        !isNaN(changedEndDate.getTime())
      ) {
        setFilters((prev) => ({
          ...prev,
          endDate: changedEndDate,
          startDate: changedStartDate,
        }));
        querySearch.set(GLOBAL.page, `${GLOBAL.default_page}`);
        navigate(`?${querySearch.toString()}`);
      }
    }
  };

  return (
    <div className="text_input_container">
      <div className="input_field">
        <div className="input_filed_content">
          <DatePicker
            selectsRange={true}
            startDate={startDate ?? undefined}
            endDate={endDate ?? undefined}
            onChange={(update) => {
              setDateRange(update);
              if (update[0] && update[1]) {
                querySearch.set(GLOBAL.page, `${GLOBAL.default_page}`);
                navigate(`?${querySearch.toString()}`);
                setFilters((prev) => ({
                  ...prev,
                  startDate: update[0],
                  endDate: update[1],
                }));
              }

              if (!update[0] && !update[1]) {
                querySearch.set(GLOBAL.page, `${GLOBAL.default_page}`);
                navigate(`?${querySearch.toString()}`);
                setFilters((prev) => ({
                  ...prev,
                  startDate: null,
                  endDate: null,
                }));
              }
            }}
            isClearable
            onBlur={handleOnBlur}
            placeholderText={placeholder}
            maxDate={new Date()}
            customInput={
              <input
                name={name}
                id={name}
                className={`text_input date_custom_input`}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};
