import { useRef } from "react";
import { ModalContainer } from "../ModalAuth";

import { useTranslation } from "localization";
import { MainButton } from "components";
import useOutsideClick from "hooks/useOutsideClick";
import { useAppDispatch } from "reduxState/store";
import { setShowPartiallyBlockModal } from "reduxState/features/main";
import { GLOBAL } from "constants/global";

import closeIcon from "assets/images/close.svg";
import icon from "assets/images/blockedUser.svg";

export const PartiallyBlockModal = () => {
  const translation = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(setShowPartiallyBlockModal(false));
  };

  const redirectToTelegram = () => {
    window.open(GLOBAL.telegram_link, "_blank");
  };

  useOutsideClick({ ref, handler: handleClose });

  return (
    <ModalContainer>
      <div className="modal" ref={ref}>
        <div className="logout_header">
          <div className="close_modal">
            <img src={closeIcon} alt="Close" onClick={handleClose} />
          </div>
          <div className="logout_header_element">
            <img src={icon} alt="Logout" className="logout_image" />
            <p className="logout_title">{translation.account_restricted}</p>
          </div>
        </div>
        <div>
          <div className="logout_body">
            <p className="logout_description">
              {translation.account_restricted_text}
            </p>
          </div>
        </div>
        <div className="logout_actions">
          <MainButton
            name={translation.support}
            customClass="custom_button width_100"
            onClick={redirectToTelegram}
          />
          <MainButton
            name={translation.close}
            customClass="log_in_button width_100"
            onClick={handleClose}
          />
        </div>
      </div>
    </ModalContainer>
  );
};
