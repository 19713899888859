import { FC, useEffect, useState } from "react";

import { IBalanceTableHeader } from "types";
import { FilterMultipleSelect, SelectCurrencyBox } from "components";
import { SOURCE_FILTER } from "constants/table";

export const TableHeader: FC<IBalanceTableHeader> = ({
  selectedCurrencyShortName,
  setSelectedCurrencyShortName,
  setFilters,
}) => {
  const [selectedType, setSelectedType] = useState<string>("");
  const [openSource, setOpenSource] = useState<boolean>(false);
  const [selectedSourceName, setSelectedSourceName] = useState<string[]>([]);
  const [selectedSourceId, setSelectedSourceId] = useState<number[]>([]);

  useEffect(() => {
    setFilters(() => ({
      transactionType: selectedType,
    }));
  }, [selectedType]);
  return (
    <div className="table_header">
      <div className="currency_header_selector">
        <SelectCurrencyBox
          name="currency"
          label="Currency"
          setSelectedCurrencyShortName={setSelectedCurrencyShortName}
          selectedCurrencyShortName={selectedCurrencyShortName}
        />
      </div>
      <div className="currency_header_selector">
        <FilterMultipleSelect
          name="status"
          openSelect={openSource}
          setOpenSelect={setOpenSource}
          selectedNames={selectedSourceName}
          setSelectedNames={setSelectedSourceName}
          selectedIds={selectedSourceId}
          setSelectedIds={setSelectedSourceId}
          data={SOURCE_FILTER}
          setFilters={setFilters}
          isLocalIcon={true}
          isSingleSelect
          setSelectedType={setSelectedType}
        />
      </div>
    </div>
  );
};
