import { FC } from "react";

import { ITransactionTableFilter } from "types";
import { TableFilter } from "components";

export const TableActions: FC<ITransactionTableFilter> = ({
  openFilters,
  setOpenFilters,
}) => {
  return (
    <div className="table_actions">
      <TableFilter openFilters={openFilters} setOpenFilters={setOpenFilters} />
    </div>
  );
};
