import { FC, useRef } from "react";

import { useTranslation } from "localization";
import { ModalContainer } from "../ModalAuth";
import { MainButton } from "components/buttons";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import useOutsideClick from "hooks/useOutsideClick";
import { setWithdrawalData } from "reduxState/features/user";

import closeIcon from "assets/images/close.svg";
import logoutIcon from "assets/images/logoutModal.svg";

interface IFeePopup {
  handleClose: () => void;
  handleConfirmAction: () => void;
}

export const FeeInfoModal: FC<IFeePopup> = ({
  handleClose,
  handleConfirmAction,
}) => {
  const feeData = useAppSelector((state) => state.userData.feeData);

  const translation = useTranslation();
  const dispatch = useAppDispatch();

  const ref = useRef<HTMLDivElement>(null);

  const handleConfirm = () => {
    handleConfirmAction();
    handleClose();
  };

  const handleCancel = () => {
    dispatch(
      setWithdrawalData({
        address: "",
        amount: "",
        currency: "",
        chain: "",
        secret: "",
        currencyId: null,
      })
    );
    handleClose();
  };

  useOutsideClick({ ref, handler: handleClose });

  return (
    <ModalContainer>
      <div className="modal" ref={ref}>
        <div className="logout_header">
          <div className="close_modal">
            <img src={closeIcon} alt="Close" onClick={handleClose} />
          </div>
          <div className="logout_header_element">
            <img src={logoutIcon} alt="Logout" className="logout_image" />
            <p className="logout_title">{translation.fee_calculation}</p>
          </div>
        </div>
        <div className="logout_body fee_modal_body">
          <p className="logout_description">{translation.fee_modal_text}</p>
          <p className="logout_description align_start">
            {translation.fee}{" "}
            <span className="description_amount">{feeData?.fee}</span>{" "}
            {translation.fee_text}
          </p>
          <p className="logout_description align_start">
            {translation.actual_amount}{" "}
            <span className="description_amount">{feeData?.willTransfer}</span>
          </p>
        </div>
        <div className="logout_actions">
          <MainButton
            name={translation.confirm}
            customClass="custom_button width_100"
            onClick={handleConfirm}
          />
          <MainButton
            name={translation.cancel}
            customClass="log_in_button width_100"
            onClick={handleCancel}
          />
        </div>
      </div>
    </ModalContainer>
  );
};
