import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";

import { useTranslation } from "localization";
import { DateInput, FilterInput, FilterMultipleSelect } from "components";
import { ITableFilters } from "types";
import { STATUS_FILTER } from "constants/table";
import { TRADE_FORMAT_TYPES } from "constants/trade";

interface ITableFiltersTypes {
  setFilters: Dispatch<SetStateAction<ITableFilters>>;
  openFilters: boolean;
  activeFormat: string;
}

export const TableFilters: FC<ITableFiltersTypes> = ({
  setFilters,
  openFilters,
  activeFormat,
}) => {
  const [nickname, setNickname] = useState<string>("");
  const [selectedStatusName, setSelectedStatusName] = useState<string[]>([]);
  const [selectedStatusValues, setSelectedStatusValues] = useState<string[]>(
    []
  );
  const [selectedStatusesId, setSelectedStatusesId] = useState<number[]>([]);
  const [openStatuses, setOpenStatuses] = useState<boolean>(false);

  const translation = useTranslation();

  useEffect(() => {
    setSelectedStatusName([]);
    setSelectedStatusesId([]);
    setSelectedStatusValues([]);
  }, [activeFormat]);

  return (
    <div
      className={`table_filters transaction_table_filter ${
        openFilters ? "filters_open transaction_filters_open" : "filters_close"
      }`}
    >
      <div className="nickname_filter">
        <FilterInput
          name="id_nickname"
          value={nickname}
          setValue={setNickname}
          placeholder={translation.id}
          setFilters={setFilters}
        />
      </div>
      {activeFormat === TRADE_FORMAT_TYPES.ONLINE && (
        <div className="status_filter">
          <FilterMultipleSelect
            name="status"
            openSelect={openStatuses}
            setOpenSelect={setOpenStatuses}
            selectedNames={selectedStatusName}
            setSelectedNames={setSelectedStatusName}
            selectedIds={selectedStatusesId}
            setSelectedIds={setSelectedStatusesId}
            data={STATUS_FILTER}
            setFilters={setFilters}
            isTransaction={true}
            setSelectedValues={setSelectedStatusValues}
            selectedValues={selectedStatusValues}
          />
        </div>
      )}
      <div
        className={`${
          activeFormat === TRADE_FORMAT_TYPES.ONLINE
            ? "date_filter"
            : "status_filter"
        }`}
      >
        <DateInput
          name="date"
          placeholder={translation.date}
          setFilters={setFilters}
        />
      </div>
    </div>
  );
};
