import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useTranslation } from "localization";
import { ModalContainer } from "../ModalAuth";
import { MainButton } from "components/buttons";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { setShowActivate } from "reduxState/features/main";
import { changeTradeStatus } from "reduxState";
import useOutsideClick from "hooks/useOutsideClick";
import { BUTTON_ACTIONS, TRADE_STATUSES } from "constants/trade";
import { setOpenToast } from "reduxState/features/toast";

import closeIcon from "assets/images/close.svg";
import activateIcon from "assets/images/trades/activatePopup.svg";
import inactivateIcon from "assets/images/trades/inactivatePopup.svg";
import { GLOBAL } from "constants/global";

export const ActivateInActivateTrade = () => {
  const [disableConfirm, setDisableConfirm] = useState<boolean>(false);

  const elementData = useAppSelector((state) => state.main.tradeActivationData);
  const loading = useAppSelector((state) => state.trades.changeStatusLoading);
  const querySearch = new URLSearchParams(useLocation().search);

  const queryPage = querySearch.get(GLOBAL.page);
  const queryPerPage = querySearch.get(GLOBAL.per_page);

  const translation = useTranslation();
  const dispatch = useAppDispatch();

  const ref = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    dispatch(
      setShowActivate({
        isOpen: false,
        id: null,
        type: "",
        tab: "",
        format: "",
      })
    );
  };

  const handleConfirm = () => {
    dispatch(
      changeTradeStatus({
        id: `${elementData?.id}`,
        data: { status: TRADE_STATUSES[elementData.type] },
        navigate: handleClose,
        tab: elementData.tab,
        format: elementData.format,
        setDisableConfirm: setDisableConfirm,
        page: `${queryPage}`,
        per_page: `${queryPerPage}`,
      })
    ).then(() => {
      setTimeout(() => {
        dispatch(setOpenToast(false));
      }, 3000);
    });
  };

  useOutsideClick({ ref, handler: handleClose });

  return (
    <ModalContainer>
      <div className="modal" ref={ref}>
        <div className="logout_header">
          <div className="close_modal">
            <img src={closeIcon} alt="Close" onClick={handleClose} />
          </div>
          <div className="logout_header_element">
            {elementData?.type === TRADE_STATUSES.active ? (
              <img
                src={activateIcon}
                alt="Inactivate"
                className="logout_image"
              />
            ) : (
              <img
                src={inactivateIcon}
                alt="Activate"
                className="logout_image"
              />
            )}
            <p className="logout_title">{`${
              translation[BUTTON_ACTIONS[elementData?.type]]
            } ${translation.trade}`}</p>
          </div>
        </div>
        <div className="logout_body">
          <p className="logout_description">
            {translation[`${BUTTON_ACTIONS[elementData?.type]}_trade`]}
          </p>
        </div>
        <div className="logout_actions">
          <MainButton
            name={translation.yes}
            customClass="custom_button width_100"
            onClick={handleConfirm}
            loading={loading}
            disabled={loading || disableConfirm}
          />
          <MainButton
            name={translation.cancel}
            customClass="log_in_button width_100"
            onClick={handleClose}
            disabled={loading}
          />
        </div>
      </div>
    </ModalContainer>
  );
};
