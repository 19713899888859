import { useLocation, useNavigate } from "react-router-dom";

import { GLOBAL, PATHNAMES } from "constants/global";
import { useTranslation } from "localization";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { setOpenToast, setToastData } from "reduxState/features/toast";
import { TOAST_MESSAGES, TOAST_TYPES } from "constants/toast";

import qrCodeImage from "assets/images/qrCode.svg";
import withdrawImage from "assets/images/profile/withdrawal.svg";
import copyIcon from "assets/images/copy.svg";

const imageUrl = process.env.REACT_APP_API_IMAGE_URL;

export const CurrencyInfo = () => {
  const translation = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const query = new URLSearchParams(useLocation().search);
  const selectedWalletId = query.get(GLOBAL.wallet_id);

  const user = useAppSelector((state) => state.auth.user);
  const wallets = user?.wallets;

  const selectedElement = wallets?.find(
    (item) => `${item.id}` === selectedWalletId
  );

  const handleCopyHashCode = () => {
    navigator.clipboard.writeText(selectedElement?.walletAddress || "");
    dispatch(
      setToastData({
        type: TOAST_TYPES.success,
        message: TOAST_MESSAGES.copy_code_success,
        open: true,
      })
    );
    setTimeout(() => {
      dispatch(setOpenToast(false));
    }, 3000);
  };

  const handleWithdraw = () => {
    navigate(PATHNAMES.withdraw);
  };

  return (
    <div
      className={`currency_info_field ${
        !!selectedWalletId ? "show_currency_info" : "hide_currency_info"
      }`}
    >
      <div className="crypto_info_field">
        <div className="crypto_info">
          <div className="about_crypto">
            <img
              src={`${imageUrl}${selectedElement?.icon}`}
              crossOrigin="anonymous"
              alt="Crypto"
              className="crypto_icon"
            />
            <div className="crypto_name">
              <p className="crypto_long_name">
                {selectedElement?.currencyFullTitle}
              </p>
              <p className="crypto_long_name_short">{selectedElement?.name}</p>
            </div>
          </div>
          <p className="crypto_message">{translation.please_scan_qr}</p>
        </div>
        <div className="qr_code_field">
          <img
            src={`${selectedElement?.addressQrQode}`}
            alt="QR"
            className="qr_code_image"
          />
        </div>
      </div>
      <div className="code_field">
        <p className="wallet_address">{translation.wallet_address}</p>
        <div className="copy_code_field">
          <p className="hash_code">{selectedElement?.walletAddress}</p>
          <img
            src={copyIcon}
            alt="Copy"
            className="copy_icon"
            onClick={handleCopyHashCode}
          />
        </div>
      </div>
      <div className="withdraw_field" onClick={handleWithdraw}>
        <img className="withdraw_image" src={withdrawImage} alt="Withdraw" />
        <p className="withdraw">{translation.withdrawal}</p>
      </div>
    </div>
  );
};
