import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { COUNTRIES, DEFAULT_LANGUAGE, LANGUAGE, MENU } from "constants/header";
import { useTranslation } from "localization";
import { GLOBAL, USER_TYPES } from "constants/global";
import { useAppDispatch, useAppSelector } from "reduxState/store";
import { setUserLoading } from "reduxState/features/auth";

import arrowDownIcon from "assets/images/arrow-down.svg";

interface IMobileNavbar {
  handleClose: () => void;
  handleLanguageChange: () => void;
}

export const MobileNavbar: FC<IMobileNavbar> = ({
  handleClose,
  handleLanguageChange,
}) => {
  const [userType, setUserType] = useState<string>(USER_TYPES.guest);
  const [openLanguages, setOpenLanguages] = useState<boolean>(false);

  const user = useAppSelector((state) => state.auth.user);

  const translation = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const language = localStorage.getItem(LANGUAGE) || DEFAULT_LANGUAGE;

  const selectedLanguage =
    COUNTRIES.find((elem) => elem.prefix === language) || COUNTRIES[0];

  const mobileMenuElement = document.getElementById("mobile_menu_id");

  const handleOpenLanguages = () => {
    setOpenLanguages(!openLanguages);
  };

  const handleChangeLanguage = (prefix: string) => {
    handleLanguageChange();
    dispatch(setUserLoading(true));
    handleOpenLanguages();
    localStorage.setItem(LANGUAGE, prefix);
    sessionStorage.setItem(GLOBAL.loading, GLOBAL.loading);
    navigate(0);
  };

  const handleRoute = (path: string) => {
    navigate(path);
    handleClose();
  };

  useEffect(() => {
    setUserType(!!user ? user?.role : USER_TYPES.guest);
  }, [user]);

  useEffect(() => {
    if (openLanguages && mobileMenuElement) {
      mobileMenuElement.scrollTo({
        behavior: "smooth",
        top: mobileMenuElement.clientHeight,
      });
    }
  }, [openLanguages]);

  return (
    <div
      className={`mobile_navbar_section ${
        openLanguages ? "mobile_navbar_opened" : ""
      }`}
    >
      <ul className="profile_actions_list">
        {MENU?.[userType]?.map((element) => (
          <li
            key={element.id}
            className="profile_list_element"
            onClick={() => handleRoute(element.path)}
          >
            <p className="profile_element_name">{translation[element.name]}</p>
          </li>
        ))}
        <li
          className="profile_list_element language_element"
          onClick={handleOpenLanguages}
        >
          <p className="profile_element_name">{translation.language}</p>
          <div className="language_container">
            <img src={selectedLanguage.image} alt="Flag" />
            <img
              src={arrowDownIcon}
              alt="Arrow"
              className={`${openLanguages ? "rotate_icon" : "arrow_icon"}`}
            />
          </div>
        </li>
      </ul>
      <div className={`language_list_container ${openLanguages ? "" : ""}`}>
        <ul
          className={`language_list ${
            openLanguages ? "show_language_list" : ""
          }`}
        >
          {COUNTRIES.map((element) => (
            <li
              key={element.id}
              className="language_element"
              onClick={() => handleChangeLanguage(element.prefix)}
            >
              <img src={element.image} alt="Flag" className="language_flag" />
              <p className="language_element_name">
                {translation[element.name]}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
