import withdrawIcon from "assets/images/balance/withdraw.svg";
import transferIcon from "assets/images/balance/transfer.svg";
import depositIcon from "assets/images/balance/deposit.svg";
import outcomeIcon from "assets/images/balance/outcome.svg";
import incomeIcon from "assets/images/balance/income.svg";
import { TRADE_REQUEST_STATUSES } from "./trade";

export const HOME_PAGE_TABS = [
  {
    id: 1,
    title: "all",
    value: "",
  },
  {
    id: 2,
    title: "buy",
    value: "buy",
  },
  {
    id: 3,
    title: "sell",
    value: "sell",
  },
];

export const HOME_PAGE_TABS_BASIC = [
  {
    id: 1,
    title: "all",
    value: "",
  },
  {
    id: 2,
    title: "buy",
    value: "sell",
  },
  {
    id: 3,
    title: "sell",
    value: "buy",
  },
];

export const HOME_PAGE_TABLE_HEADER = [
  {
    id: 1,
    name: "merchant",
  },
  {
    id: 2,
    name: "currency",
  },
  {
    id: 3,
    name: "crypto_coin",
  },
  {
    id: 4,
    name: "usd",
  },
  {
    id: 5,
    name: "usd_to_amd",
  },
  {
    id: 6,
    name: "banks",
  },
  {
    id: 7,
    name: "trade",
  },
];

export const HOME_PAGE_TABLE_HEADER_OFFLINE = [
  {
    id: 1,
    name: "merchant",
  },
  {
    id: 2,
    name: "currency",
  },
  {
    id: 3,
    name: "crypto_coin",
  },
  {
    id: 4,
    name: "usd",
  },
  {
    id: 5,
    name: "usd_to_amd",
  },
  {
    id: 6,
    name: "location",
  },
  {
    id: 7,
    name: "trade",
  },
];

export const HOME_PAGE_MERCHANT_TABLE_HEADER = [
  {
    id: 1,
    name: "merchant",
  },
  {
    id: 2,
    name: "currency",
  },
  {
    id: 3,
    name: "crypto_coin",
  },
  {
    id: 4,
    name: "usd",
  },
  {
    id: 5,
    name: "usd_to_amd",
  },
  {
    id: 6,
    name: "banks",
  },
];

export const HOME_PAGE_MERCHANT_TABLE_HEADER_OFFLINE = [
  {
    id: 1,
    name: "merchant",
  },
  {
    id: 2,
    name: "currency",
  },
  {
    id: 3,
    name: "crypto_coin",
  },
  {
    id: 4,
    name: "usd",
  },
  {
    id: 5,
    name: "usd_to_amd",
  },
  {
    id: 6,
    name: "location",
  },
];

export const CONTENT_DATA = {
  title: "",
  content: "",
};

export const CONTACT_US_DATA = {
  id: null,
  phone: "",
  address: "",
  email: "",
};

export const SOCIAL_LINKS_DATA = {
  id: null,
  title: "",
  link: "",
  icon: "",
};

export const BANKS_MAX_LENGTH = 1;

export const TABLE_BUY_SELL: { [key: string]: string } = {
  buy: "sell",
  sell: "buy",
};

export const PAGINATION_PER_PAGE = [
  {
    id: 1,
    count: 5,
  },
  {
    id: 2,
    count: 10,
  },
  {
    id: 3,
    count: 20,
  },
  {
    id: 4,
    count: 30,
  },
];

export const TRANSACTION_TABLE_HEADER = [
  {
    id: 1,
    name: "deal_id",
  },
  {
    id: 2,
    name: "currency",
  },
  {
    id: 3,
    name: "crypto_coin",
  },
  {
    id: 4,
    name: "usd_to_amd",
  },
  {
    id: 5,
    name: "status",
  },
  {
    id: 6,
    name: "date",
  },
  {
    id: 7,
    name: "action",
  },
];

export const BALANCE_TABLE_HEADER = [
  {
    id: 1,
    name: "crypto_coin",
  },
  {
    id: 5,
    name: "currency",
  },
  {
    id: 2,
    name: "status",
  },
  {
    id: 3,
    name: "type",
  },
  // Temporary hide
  // {
  //   id: 4,
  //   name: "date",
  // },
];

export const STATUS_FILTER = [
  {
    id: 1,
    title: "Canceled",
    value: TRADE_REQUEST_STATUSES.rejected,
  },
  {
    id: 2,
    title: "Completed",
    value: TRADE_REQUEST_STATUSES.completed,
  },
  {
    id: 3,
    title: "Closed",
    value: TRADE_REQUEST_STATUSES.closed,
  },
];

export const BALANCE_TABLE_TABS = [
  {
    id: 1,
    title: "all",
    value: "",
  },
  {
    id: 2,
    title: "income",
    value: "in",
  },
  {
    id: 3,
    title: "outcome",
    value: "out",
  },
];

export const SOURCE_FILTER = [
  {
    id: 1,
    title: "withdraw",
    icon: withdrawIcon,
    value: "Withdrawal",
  },
  {
    id: 2,
    title: "deposit",
    icon: depositIcon,
    value: "Deposit",
  },
  {
    id: 3,
    title: "transfer",
    icon: transferIcon,
    value: "P2P",
  },
];

export const IN_OUT_COME: { [key: string]: string } = {
  IN: incomeIcon,
  OUT: outcomeIcon,
};

export const IN_OUT_COME_NAME: { [key: string]: string } = {
  IN: "income",
  OUT: "outcome",
};

export const BALANCE_SOURCE_TYPES: { [key: string]: string } = {
  P2P: "transfer",
  WITHDRAW: "withdraw",
};

export const BALANCE_SOURCE_TYPE_ICONS: { [key: string]: string } = {
  P2P: transferIcon,
  WITHDRAW: withdrawIcon,
};
