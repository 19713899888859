import { FC } from "react";

import { IMainTableElement } from "types";
import { useTranslation } from "localization";
import { useAppSelector } from "reduxState/store";
import {
  BALANCE_SOURCE_TYPES,
  BALANCE_SOURCE_TYPE_ICONS,
  IN_OUT_COME,
  IN_OUT_COME_NAME,
} from "constants/table";

interface ITable {
  headerData: IMainTableElement[];
}

export const DesktopTable: FC<ITable> = ({ headerData }) => {
  const translation = useTranslation();

  const data = useAppSelector((state) => state.userData.balanceHistory);

  return (
    <div className="table_container_desktop">
      <table
        cellPadding="1"
        cellSpacing="1"
        bgcolor="#20212c"
        className="table"
      >
        <thead>
          <tr>
            {headerData.map((element) => (
              <th key={element.id} className="header_element">
                {translation[element.name]}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((balance, index) => (
            <tr key={index}>
              <td className="table_body_element">
                {Number(balance?.amount)?.toFixed(2)}
              </td>
              <td className="table_body_element">{balance.currency.name}</td>
              <td className="table_body_element">
                <div className="price_row">
                  <img
                    src={BALANCE_SOURCE_TYPE_ICONS[balance?.transactionType]}
                    alt="Currency"
                    className="amd_currency_icon"
                  />
                  <span>
                    {
                      translation[
                        BALANCE_SOURCE_TYPES[balance?.transactionType]
                      ]
                    }
                  </span>
                </div>
              </td>
              <td className="table_body_element">
                <div className="price_row">
                  <img
                    src={IN_OUT_COME[balance?.direction]}
                    alt="Currency"
                    className="amd_currency_icon"
                  />
                  <span>
                    {translation[IN_OUT_COME_NAME[balance?.direction]]}
                  </span>
                </div>
              </td>
              {/* Temporary hide */}
              {/* <td className="table_body_element">
                {formatDate(balance?.date)}
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
