import { FC, useState } from "react";
import { useLocation } from "react-router-dom";

import { TableBody, TableFilters, TableHeader } from "./components";
import { IMainTable, ITableFilters } from "types";
import {
  HOME_PAGE_MERCHANT_TABLE_HEADER,
  HOME_PAGE_MERCHANT_TABLE_HEADER_OFFLINE,
  HOME_PAGE_TABLE_HEADER,
  HOME_PAGE_TABLE_HEADER_OFFLINE,
  HOME_PAGE_TABS,
  HOME_PAGE_TABS_BASIC,
} from "constants/table";
import { FORMAT_DATA, GLOBAL } from "constants/global";
import { MainTab } from "components/tabs";

export const MainTable: FC<IMainTable> = ({ isMerchant }) => {
  const querySearch = new URLSearchParams(useLocation().search);
  const format = querySearch.get(GLOBAL.format);

  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [filters, setFilters] = useState<ITableFilters>({});
  const [refresh, setRefresh] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>(
    isMerchant ? HOME_PAGE_TABS[0].value : HOME_PAGE_TABS_BASIC[0].value
  );
  const [activeFormat, setActiveFormat] = useState<string>(
    format ?? FORMAT_DATA[0].value
  );

  const headerData = isMerchant
    ? activeFormat === FORMAT_DATA[0].value
      ? HOME_PAGE_MERCHANT_TABLE_HEADER
      : HOME_PAGE_MERCHANT_TABLE_HEADER_OFFLINE
    : activeFormat === FORMAT_DATA[0].value
    ? HOME_PAGE_TABLE_HEADER
    : HOME_PAGE_TABLE_HEADER_OFFLINE;

  const onTabChange = () => {
    setFilters({ ...filters, banks: [] });
  };

  return (
    <div className="main_table_container" id="home_page_table">
      <div className="main_tab_home_page">
        <MainTab
          data={FORMAT_DATA}
          setActiveTab={setActiveFormat}
          activeTab={activeFormat}
          customClass="trades_tabs"
          onTabChange={onTabChange}
          isFormatChange
        />
      </div>
      <div className="main_table_content">
        <TableHeader
          setOpenFilters={setOpenFilters}
          openFilters={openFilters}
          refresh={refresh}
          setRefresh={setRefresh}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isMerchant={isMerchant}
        />
        <div
          className={`filters_container ${
            openFilters ? "filter_container_show" : ""
          }`}
        >
          <TableFilters
            setFilters={setFilters}
            openFilters={openFilters}
            activeFormat={activeFormat}
          />
        </div>
        <TableBody
          headerData={headerData}
          filters={filters}
          refresh={refresh}
          activeTab={activeTab}
          activeFormat={activeFormat}
        />
      </div>
    </div>
  );
};
