import { Dispatch, FC, MouseEvent, SetStateAction, useState } from "react";
import { Checkbox } from "../checkbox";
import { IBank } from "types";
import { useTranslation } from "localization";

const imageUrl = process.env.REACT_APP_API_IMAGE_URL;

interface ISelect {
  element: IBank;
  isChecked: boolean;
  icon?: string;
  handleSelect: (
    e: MouseEvent<HTMLElement>,
    id: number,
    value: string,
    setChecked: Dispatch<SetStateAction<boolean>>,
    typeValue?: string
  ) => void;
  isLocalIcon: boolean;
  isSingleSelect: boolean;
}

export const SelectElement: FC<ISelect> = ({
  handleSelect,
  element,
  isChecked,
  icon,
  isLocalIcon,
  isSingleSelect,
}) => {
  const [checked, setChecked] = useState<boolean>(isChecked);

  const translation = useTranslation();

  return (
    <div
      className={`select_element ${
        isSingleSelect && isChecked ? "selected_element" : ""
      }`}
      onClick={(e) =>
        handleSelect(
          e,
          element.id,
          element.currencyFullTitle ? element.currencyFullTitle : element.title,
          setChecked,
          element?.value
        )
      }
    >
      <div className="select_left_section">
        {icon && (
          <img
            src={isLocalIcon ? icon : `${imageUrl}${icon}`}
            alt="Icon"
            className="select_icon"
            crossOrigin="anonymous"
          />
        )}
        {element?.currencyFullTitle ? (
          <div className="element_name_field">
            <p className="element_name">{element.currencyFullTitle}</p>
            <span className="currency_line"></span>
            <p className="element_name">{element.name}</p>
          </div>
        ) : (
          <p className="element_name">
            {isSingleSelect ? translation[element.title] : element.title}
          </p>
        )}
      </div>
      {!isSingleSelect && (
        <div className="select_right_section">
          <Checkbox checked={checked} setChecked={setChecked} />
        </div>
      )}
    </div>
  );
};
