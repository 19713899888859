import {
  IBalanceParams,
  IError,
  IFeeCalculationData,
  IWithdrawAction,
} from "types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { userApi } from "service";
import { setToastData } from "reduxState/features/toast";
import { TOAST_TYPES } from "constants/toast";

export const getBalanceHistory = createAsyncThunk(
  "getBalanceHistory",
  async ({ params }: IBalanceParams, { rejectWithValue }) => {
    try {
      const { data } = await userApi.getBalanceHistory(params);
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const withdrawCurrency = createAsyncThunk(
  "withdrawCurrency",
  async (
    {
      data,
      handleConfirmAction,
    }: { data: IWithdrawAction; handleConfirmAction: () => void },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await userApi.withdrawCurrency(data);

      handleConfirmAction();
      dispatch(
        setToastData({
          type: TOAST_TYPES.success,
          message: res.data.message,
          open: true,
        })
      );
    } catch (error) {
      const typedError = error as IError;

      dispatch(
        setToastData({
          type: TOAST_TYPES.error,
          message: typedError.response.data.message,
          open: true,
        })
      );
      return rejectWithValue(error);
    }
  }
);

export const feeCalculation = createAsyncThunk(
  "feeCalculation",
  async (
    { data, handleOpenFeePopup }: IFeeCalculationData,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const res = await userApi.feeCalculation(data);

      handleOpenFeePopup();
      return res?.data;
    } catch (error) {
      const typedError = error as IError;

      dispatch(
        setToastData({
          type: TOAST_TYPES.error,
          message: typedError.response.data.message,
          open: true,
        })
      );
      return rejectWithValue(error);
    }
  }
);
