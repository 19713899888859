import { useMemo, useState } from "react";

import { useTranslation } from "localization";
import { useAppSelector } from "reduxState/store";
import { IWallet } from "types";

import balanceIcon from "assets/images/profile/profileBalance.svg";
import eyeSlashIcon from "assets/images/profile/eye-slash.svg";
import eyeIcon from "assets/images/profile/eye.svg";

export const BalanceSection = () => {
  const [showBalance, setShowBalance] = useState<boolean>(true);

  const user = useAppSelector((state) => state.auth.user);
  const currencyRates = useAppSelector(
    (state) => state.banksCurrencies.currenciesRate
  );

  const balance = useMemo(() => {
    if (user?.wallets?.length && currencyRates) {
      const total = user?.wallets?.reduce((acc: number, curr: IWallet) => {
        return (acc +=
          Number(curr.quantity) * Number(currencyRates[curr.name]?.price));
      }, 0);

      return total;
    }

    return 0;
  }, [currencyRates, user]);

  const translation = useTranslation();

  const handleEyeClick = () => {
    setShowBalance(!showBalance);
  };

  return (
    <div className="balance_section">
      <div className="balance_header">
        <img src={balanceIcon} alt="Balance" className="balance_icon" />
        <p className="balance_title">{translation.balance}</p>
        {showBalance ? (
          <img
            className="show_hide_icon"
            src={eyeIcon}
            alt="Eye"
            onClick={handleEyeClick}
          />
        ) : (
          <img
            className="show_hide_icon"
            src={eyeSlashIcon}
            alt="Eye"
            onClick={handleEyeClick}
          />
        )}
      </div>
      <div className="user_balance">
        <p className={`balance ${showBalance ? "hide_balance" : ""}`}>
          {`$${balance?.toFixed(2)}`}
        </p>
      </div>
    </div>
  );
};
