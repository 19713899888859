import { IBalanceHistory } from "types";
import { createSlice } from "@reduxjs/toolkit";

import {
  getBalanceHistory,
  withdrawCurrency,
  feeCalculation,
} from "reduxState";

const initialState: IBalanceHistory = {
  balanceHistory: [],
  balanceLoading: false,
  balanceTotal: 0,
  withdrawalData: {
    address: "",
    amount: "",
    currency: "",
    chain: "",
    secret: "",
    currencyId: null,
  },
  isPassedFirstStep: false,
  withdrawLoading: false,
  feeData: {
    fee: 0,
    willTransfer: 0,
    amount: 0,
    currency: "",
    network: "",
    toAddress: "",
  },
};

export const userSlice = createSlice({
  name: "User",
  initialState: initialState,
  reducers: {
    setWithdrawalData: (state, { payload }) => {
      state.withdrawalData = payload;
    },
    setIsPassedFirstStep: (state, { payload }) => {
      state.isPassedFirstStep = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getBalanceHistory.pending, (state) => {
        state.balanceLoading = true;
      })
      .addCase(getBalanceHistory.fulfilled, (state, { payload }) => {
        state.balanceHistory = payload?.items;
        state.balanceTotal = payload?.total ? Number(payload?.total) : 0;
        state.balanceLoading = false;
      })
      .addCase(getBalanceHistory.rejected, (state) => {
        state.balanceLoading = false;
      });
    builder
      .addCase(withdrawCurrency.pending, (state) => {
        state.withdrawLoading = true;
      })
      .addCase(withdrawCurrency.fulfilled, (state, { payload }) => {
        state.withdrawLoading = false;
      })
      .addCase(withdrawCurrency.rejected, (state) => {
        state.withdrawLoading = false;
      });
    builder.addCase(feeCalculation.fulfilled, (state, { payload }) => {
      state.withdrawLoading = false;
      state.feeData = payload?.data;
    });
  },
});

export const { setWithdrawalData, setIsPassedFirstStep } = userSlice.actions;

export default userSlice.reducer;
