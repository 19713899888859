import {
  FC,
  Fragment,
  MouseEvent,
  RefObject,
  memo,
  useEffect,
  useState,
} from "react";

import { useAppSelector } from "reduxState/store";
import { TradeConfirmed } from "./TradeConfirmStatus";
import { getHoursMinutes } from "helpers";
import { ChatImagesModal } from "components/modals";
import { MOBILE_WINDOW_WIDTH } from "constants/global";
import {
  IMAGES_COUNT,
  MESSAGE_NOTIFICATION_TYPES,
  TRADE_FORMAT_TYPES,
} from "constants/trade";
import { IChatMessages } from "types";
import { ChatImages } from "./ChatImages";

interface IMessageData {
  messages: IChatMessages[];
  messagesEndRef: RefObject<HTMLDivElement>;
}

export const ChatMessages: FC<IMessageData> = memo(
  ({ messages, messagesEndRef }) => {
    const [showImagesModal, setShowImagesModal] = useState<boolean>(false);
    const [showAllImages, setShowAllImages] = useState<boolean>(false);
    const [selectedImages, setSelectedImages] = useState<string[] | File[]>([]);
    const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);

    const user = useAppSelector((state) => state.auth.user);
    const requestData = useAppSelector((state) => state.trades.userRequestData);

    const innerWidth = window.innerWidth;
    const shownImagesCount =
      innerWidth > MOBILE_WINDOW_WIDTH
        ? IMAGES_COUNT.desktop
        : IMAGES_COUNT.mobile;

    const handleShowAllImages = (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      setShowAllImages(true);
    };

    const handleImagePreview = (images: string[] | File[], index: number) => {
      setSelectedImageIndex(index);
      setSelectedImages(images);
      setShowImagesModal(true);
    };

    const handleCloseModal = () => {
      setSelectedImageIndex(0);
      setSelectedImages([]);
      setShowImagesModal(false);
    };

    useEffect(() => {
      if (!showImagesModal) {
        document.body.style.overflow = "unset";
      }
    }, [showImagesModal]);

    return (
      <div className="chat_messages_section" id="chat_messages_section_id">
        {showImagesModal && (
          <ChatImagesModal
            onClose={handleCloseModal}
            images={selectedImages}
            selectedImageIndex={selectedImageIndex}
          />
        )}
        <div className="chat_messages">
          {messages?.map((element) =>
            element.is_arbitraged ? (
              <div className="notification_message">
                <TradeConfirmed
                  type={MESSAGE_NOTIFICATION_TYPES.ARBITRAGE}
                  sender_id={element.sender_id}
                  sender_name={element.sender_name}
                  date={element.created_at as Date}
                  receiver={requestData?.requester}
                  isOnlineFormat={
                    requestData?.format === TRADE_FORMAT_TYPES.ONLINE
                  }
                />
              </div>
            ) : element.request_state ? (
              <div className="notification_message">
                <TradeConfirmed
                  type={
                    element.request_state ===
                    MESSAGE_NOTIFICATION_TYPES.COMPLETED
                      ? MESSAGE_NOTIFICATION_TYPES.COMPLETED
                      : MESSAGE_NOTIFICATION_TYPES.REJECTED
                  }
                  sender_name={element.sender_name}
                  sender_id={element.sender_id}
                  date={element.created_at as Date}
                  receiver={requestData?.requester}
                  isOnlineFormat={
                    requestData?.format === TRADE_FORMAT_TYPES.ONLINE
                  }
                />
              </div>
            ) : element.admin_action ? (
              <div className="notification_message">
                <TradeConfirmed
                  type={
                    element.admin_action ===
                    MESSAGE_NOTIFICATION_TYPES.COMPLETED
                      ? MESSAGE_NOTIFICATION_TYPES.ADMIN_COMPLETED
                      : MESSAGE_NOTIFICATION_TYPES.ADMIN_REJECTED
                  }
                  isAdminAction={true}
                  sender_name={element.sender_name}
                  sender_id={element.sender_id}
                  date={element.created_at as Date}
                  receiver={requestData?.requester}
                  isOnlineFormat={
                    requestData?.format === TRADE_FORMAT_TYPES.ONLINE
                  }
                />
              </div>
            ) : (
              <div
                key={`${element.created_at}`}
                className={`message_field ${
                  element.sender_id === user?.id ? "my_messages" : ""
                } ${element.is_admin ? "admin_message" : ""}`}
              >
                {element.sender_id !== user?.id && (
                  <p className="sender_name">{element.sender_name}</p>
                )}
                <p className="message">{element.message}</p>
                {element?.attachmentPaths &&
                  !!element?.attachmentPaths?.length && (
                    <ChatImages
                      element={element}
                      shownImagesCount={shownImagesCount}
                      showAllImages={showAllImages}
                      handleShowAllImages={handleShowAllImages}
                      handleImagePreview={handleImagePreview}
                      messagesEndRef={messagesEndRef}
                    />
                  )}
                <div className="message_date">
                  <span className="date">
                    {getHoursMinutes(element.created_at as Date)}
                  </span>
                </div>
              </div>
            )
          )}
          <div ref={messagesEndRef} />
        </div>
      </div>
    );
  }
);
