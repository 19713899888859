import { IBalanceParamsObj, IFeeCalculation, IWithdrawAction } from "types";
import instance from "../baseRequest";

export const userApi = {
  getBalanceHistory: async (params: IBalanceParamsObj) =>
    instance.get(`/v1/users/transactions/`, { params }),
  withdrawCurrency: async (data: IWithdrawAction) =>
    instance.post("/v1/users/withdraw", data),
  feeCalculation: async (data: IFeeCalculation) =>
    await instance.post(`/v1/currencies/calculate-fee`, data),
};
