import moment from "moment";

interface IDateFormat {
  date: string | Date;
  format?: string;
}

export const getHoursMinutes = (date: Date) => {
  return moment(date).format("HH:mm");
};

export const formatDate = ({ date, format = "DD/MM/YYYY" }: IDateFormat) => {
  return moment(date).format(format);
};
