import { ChangeEvent, FC, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { IFilterInput } from "types";
import { getNumericNumbers } from "helpers";
import { GLOBAL } from "constants/global";

export const FilterInput: FC<IFilterInput> = ({
  name,
  value,
  setValue,
  placeholder,
  isNumber,
  setFilters,
}) => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const querySearch = new URLSearchParams(useLocation().search);

  const navigate = useNavigate();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = isNumber ? getNumericNumbers(e.target.value) : e.target.value;

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      querySearch.set(GLOBAL.page, `${GLOBAL.default_page}`);
      navigate(`?${querySearch.toString()}`);
      setFilters((prev) => ({ ...prev, [name]: value }));
    }, 300);

    if (setValue) setValue(value);
  };

  return (
    <div className="text_input_container">
      <div className="input_field">
        <div className="input_filed_content">
          <input
            name={name}
            id={name}
            className={`text_input`}
            placeholder={placeholder}
            autoComplete="new-password"
            value={value}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};
