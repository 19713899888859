import { FC, useState } from "react";

import { TableBody, TableHeader } from "./components";
import { IMainTable, ITableFilters } from "types";
import { BALANCE_TABLE_HEADER } from "constants/table";

export const BalanceHistoryTable: FC<IMainTable> = () => {
  const [filters, setFilters] = useState<ITableFilters>({});
  const [selectedCurrencyShortName, setSelectedCurrencyShortName] =
    useState<string>("");

  return (
    <div className="main_table_container padding_0">
      <div className="main_table_content">
        <TableHeader
          setSelectedCurrencyShortName={setSelectedCurrencyShortName}
          selectedCurrencyShortName={selectedCurrencyShortName}
          setFilters={setFilters}
        />
        <TableBody
          headerData={BALANCE_TABLE_HEADER}
          filters={filters}
          selectedCurrency={selectedCurrencyShortName}
        />
      </div>
    </div>
  );
};
